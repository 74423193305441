/* eslint-disable no-secrets/no-secrets */
import React, {
    useContext,
    useEffect,
    useState,
    useMemo,
    useCallback
} from 'react';
import _ from 'lodash';
import appConfig from 'app-config';
import { Icon, Loader } from '@jutro/components';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { withRouter } from 'react-router-dom';
import { JobService } from 'gw-capability-gateway';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ICON_NAMES, WMICVariousUtil, WMICUserAccessUtil, QUOTE_STATUS, UW_BLOCKING_POINT, CONSTANTS, PRODUCT, BOT_STATUS, WMICLogger, parseErrorMessage } from 'wmic-pe-portals-utils-js';
import { WMICLink, WMICHeading, WMICIcon, WMICUnderwritingIssuesList } from 'wmic-pe-components-platform-react';
import cx from 'classnames';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { LoadSaveService } from 'wmic-pe-capability-gateway-quoteandbind';
import { WMICPremiumCosts, WMICDocuments, WMICGoogleTagManagerUtil } from 'wmic-pe-capability-gateway-common-react';
import { DocumentService } from 'wmic-pe-capability-gateway-policycommon';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import WMICQuoteUtil from 'wmic-pe-capability-quoteandbind-common-react/util/WMICQuoteUtil';
import WMICDocumentUtil from 'wmic-pe-capability-quoteandbind-common-react/util/WMICDocumentUtil';

import metadata from './WMICQuoteDetailsPage.metadata.json5';
import messages from './WMICQuoteDetailsPage.messages';
import styles from './WMICQuoteDetailsPage.module.scss';

const WMICQuoteDetailsPage = (props) => {
    const { authUserData: currentUser, authHeader } = useAuthentication();
    const translator = useContext(TranslatorContext);

    const [quote, setQuote] = useState();
    const [quoteVM, setQuoteVM] = useState();
    const [lobWithQuoteNumber, setLobWithQuoteNumber] = useState('');
    const [accountName, setAccountName] = useState('');
    const [routePath, setRoutePath] = useState('');
    const [status, setStatus] = useState('');
    const [quotedWithUW, setQuotedWithUW] = useState(false);
    const [approvedUWIssues, setApprovedUWIssues] = useState();
    const [autoApprovedUWIssues, setautoApprovedUWIssues] = useState()
    const [unapprovedUWIssues, setUnapprovedUWIssues] = useState();
    const [userDocuments, setUserDocuments] = useState([]);
    const [isBMSI, setIsBMSI] = useState(false);
    const [isSubmissionLocked, setIsSubmissionLocked] = useState(false);
    const [hasQuoteBlockingUnderwritingIssues, setHasQuoteBlockingUnderwritingIssues] = useState(false);
    const { showConfirm } = useWizardModals();
    const [isTransactionPendingWithUW, setIsTransactionPendingWithUW] = useState(false);
    const [productCode, setProductCode]  = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const canEditAccount = WMICUserAccessUtil.canEditAccount(currentUser.roles);

    const viewModelContext = {
        AccountEmailRequired: false,
        DriverEmailRequired: true,
        AccountDOBRequired: false
    };

    const getJobByJobNumber = async () => {
        const {
            match: {
                params: { jobNumber }
            },
            viewModelService,
            history
        } = props;

        try {
            setIsLoading(true);
            const submission = await JobService.findJobByJobNumber(jobNumber, authHeader);
            setLobWithQuoteNumber(
                translator(messages[_.camelCase(submission.productCode)], {
                    quoteNumber: submission.jobNumber,
                    jobId: _.get(submission, 'jobID') || ''
                })
            );

            setStatus(submission?.statusCode);
            setIsSubmissionLocked(submission?.isEditLocked ?? false);
            setIsTransactionPendingWithUW(submission?.isPendingWithUW ?? false);
            setProductCode(submission?.productCode);

            const postalCode = _.get(submission, 'policy.account.accountHolder.primaryAddress.postalCode');

            if (submission) {
                const requestData = {
                    quoteID: jobNumber,
                    postalCode
                };

                const submissionResult = await LoadSaveService.retrieveSubmission(requestData, authHeader);
                const submissionVM = viewModelService.create(
                    submissionResult,
                    'pc',
                    'wmic.edge.ca.capabilities.quote.submission.dto.QuoteDataDTO',
                    viewModelContext
                );
                setQuoteVM(submissionVM);
                setIsBMSI(_.get(submissionVM, "isFromBMS_WMIC.value", false));

                const lob = _.get(submission, 'productCode');
                const policyData = _.get(submission, 'policy');
                WMICGoogleTagManagerUtil.addPolicyDataToGTagManager(jobNumber, null, lob, policyData);
            }

            setQuote(submission);
        } catch (e) {
            WMICErrorHandler.processAsModal(parseErrorMessage(e))
            history.push("/");
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getJobByJobNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const issueApproved = (issue) => issue.botStatus === BOT_STATUS.MANUAL_APPROVED_UW_ISSUE;

    const issueAutoApproved = (issue) => issue.botStatus === BOT_STATUS.AUTO_APPROVED_UW_ISSUE;

    const quotedWithFieldValidations = quote?.statusCode === QUOTE_STATUS.QUOTED &&  _.get(quoteVM, 'errorsAndWarnings.validationIssues.issues.value', []).length > 0;

    const hasQuoteIssues =
        quote?.statusCode === QUOTE_STATUS.QUOTED &&
        (quotedWithUW || quotedWithFieldValidations);

    useEffect(() => {
        const getFirstName = _.get(quote, 'policy.account.accountHolder.firstName') || '';
        const getLastName = _.get(quote, 'policy.account.accountHolder.lastName') || '';
        const getDisplayName = _.get(quote, 'policy.account.accountHolder.displayName');
        const getAccountDisplayName = !getDisplayName
            ? `${getFirstName} ${getLastName}`
            : getDisplayName;
        const getAccountName = _.trim(getAccountDisplayName)
            || _.get(quote, 'policy.account.accountHolder.contactName')
            || _.get(quote, 'policy.account.accountName');

        setAccountName(getAccountName);

        const UWIssues = _.get(quote, 'underwritingIssues', []);
        const accountNumber = _.get(quote, 'policy.account.accountNumber');
        const approvedIssues = _.orderBy(UWIssues.filter((issue) => issueApproved(issue)), ['hasApprovalOrRejection']);
        const autoApprovedIssues = UWIssues.filter((issue) => issueAutoApproved(issue));
        const unapprovedIssues = _.orderBy(UWIssues.filter((issue) => WMICQuoteUtil.isIssueNotApproved(issue)), ['hasApprovalOrRejection', 'publicID']);
        // eslint-disable-next-line no-unused-expressions
        unapprovedIssues.length > 0 && quote?.statusCode === QUOTE_STATUS.QUOTED && setQuotedWithUW(true);
        setHasQuoteBlockingUnderwritingIssues(WMICQuoteUtil.isQuoteBlockingUWIssuePresent(unapprovedIssues));
        setApprovedUWIssues(approvedIssues);
        setautoApprovedUWIssues(autoApprovedIssues)
        setUnapprovedUWIssues(unapprovedIssues);

        setRoutePath(`/accounts/${accountNumber}/summary`);
    }, [quote])

    const fetchDocuments = useCallback(async () => {
        try {
            const documents = await DocumentService.getDocumentsForJobNumber(quoteVM.quoteID.value, authHeader)
            setUserDocuments(documents);

        } catch(err) {
            WMICLogger.error('Unable to fetch documents', err);
        }
    }, [authHeader, quoteVM]);

    useEffect(() => {
        fetchDocuments()
    }, [fetchDocuments])

    const getStatusIcon = () => {
        if (hasQuoteIssues) {
            return 'mi-warning';
        }

        if (status === QUOTE_STATUS.QUOTED) {
            return 'mi-check';
        }
        if (status === QUOTE_STATUS.DECLINED) {
            return 'mi-warning';
        }
        return 'mi-info';
    };

    const getTitleIcon = () => {
        const productCode = _.get(quote, 'productCode');
        const icon = WMICVariousUtil.getLobIcon(productCode);
        return <WMICIcon
            icon={icon}
            id="lobIcon"
            size="large"
            className={cx(styles.lobIcon, "gw-mr-2", "gw-mt-1")}
        />
    }

    const getAddress = () => _.get(quote, 'policy.account.accountHolder.primaryAddress.displayName') || ''

    const submissionData = () => (
            <div>
                <div>
                    <div className={cx("wizardHeaderJobDetails", 'gw-mb-5')}>
                        {getTitleIcon()}
                        <WMICHeading variant="h2" title={lobWithQuoteNumber} />
                    </div>
                    <div>
                        <div className={styles.accountHolderName}>
                            {accountName}
                        </div>
                        <p>
                            {getAddress()}
                            {canEditAccount && <WMICLink
                                to={routePath}
                            >
                                <Icon
                                    icon={ICON_NAMES.ICON_EDIT}
                                    id="iconEdit"
                                    size="small"
                                    className="gw-ml-1"
                                />
                                <span className={styles.editAccount}>
                                    {translator(messages.editAccount)}
                                </span>
                            </WMICLink>}
                        </p>
                    </div>
                </div>
            </div>
        );

    const updateWithDrawQuote = (withdrawnStatus, currentQuote) => {
        setStatus(withdrawnStatus);
        _.set(currentQuote, 'status', withdrawnStatus);
        _.set(currentQuote, 'statusCode', withdrawnStatus);

        setQuote((currentState) => ({...currentState, currentQuote}));
    }

    const handleWithdrawQuote = () => {
        showConfirm({
            title: translator(messages.withdrawQuoteHeading),
            message: translator(messages.withdrawQuoteBody),
            confirmButtonText: translator(messages.withdrawQuoteButton),
            cancelButtonText: commonMessages.no,
        // eslint-disable-next-line consistent-return
        }).then((result) => {
            if (result === CONSTANTS.MODAL_RESULT.CANCEL || result === CONSTANTS.MODAL_RESULT.CLOSE) {
                return _.noop();
            }

            setIsLoading(true);
            JobService.withdrawJobByJobNumber(quote.jobNumber, authHeader).then(
                () => {
                    updateWithDrawQuote(QUOTE_STATUS.WITHDRAWN, quote);
                }
            ).finally(() => {
                setIsLoading(false);
            });
        }, _.noop);
    };


    const handleContinueQuote = useCallback(() => {
        const { lobQuoteURL } = appConfig;
        const { history } = props;

        const productCode = _.get(quote, 'productCode');
        const postalCode = _.get(
            quote,
            'policy.account.accountHolder.primaryAddress.postalCode'
        );
        if (quote.jobNumber > 0) {
            if (!_.isNil(lobQuoteURL[productCode])) {
                const nextLocation = {
                    quoteentry: {
                        postalCode,
                        quoteID: quote.jobNumber
                    },
                    productCode
                };
                history.push(lobQuoteURL[productCode], nextLocation);
            }
        }
    }, [props, quote])

    const handleEditQuote = useCallback(async () => {
        try {
            await LoadSaveService.editQuotedSubmission(quoteVM.value, authHeader);
            handleContinueQuote();
        } catch (error) {
            WMICLogger.error('Failed to edit quoted submission', error);
        }
    }, [authHeader, handleContinueQuote, quoteVM]);

    const displayStatus = _.get(quoteVM, 'displayStatus_WMIC.value');
    const statusMessageHeading = useMemo(() => {

        if (isSubmissionLocked && status !== QUOTE_STATUS.QUOTED) {
            return translator(messages.quoteLockedHeading);
        }
        if (isTransactionPendingWithUW) {
            return productCode === PRODUCT.COMMERCIAL_PACKAGE ? translator(messages.underwriterApprovalPendingCL) : translator(messages.underwriterApprovalPendingPL);
        }
        if (isBMSI && status === QUOTE_STATUS.DRAFT) {
            return translator(messages.quoteDraftBMSIHeading);
        }
        if (status === QUOTE_STATUS.BOUND) {
            return translator(messages.boundSubmission);
        }
        if (status === QUOTE_STATUS.WITHDRAWN) {
            return translator(messages.quoteWithdrawnHeading);
        }
        if (quotedWithFieldValidations && quotedWithUW) {
            return translator(messages[`quote${status}UWFVHeading`]);
        }
        if (quotedWithFieldValidations) {
            return translator(messages[`quote${status}FVHeading`]);
        }
        if (quotedWithUW) {
            return translator(messages[`quote${status}UWHeading`]);
        }

        return displayStatus || translator(messages[`quote${status}Heading`]);
    }, [isSubmissionLocked, status, isBMSI, quotedWithFieldValidations, quotedWithUW, displayStatus, translator, isTransactionPendingWithUW, productCode]);

    const statusMessageBody = useMemo(() => {

        if ((isSubmissionLocked && status !== QUOTE_STATUS.QUOTED) || isTransactionPendingWithUW) {
            return productCode === PRODUCT.COMMERCIAL_PACKAGE ? translator(messages.quoteLockedBody) : translator(messages.quoteQuotedUWBody);
        }
        if (isBMSI && status === QUOTE_STATUS.DRAFT) {
            return translator(messages.quoteDraftBMSIBody);
        }
        if (status === QUOTE_STATUS.DECLINED) {
            return translator(messages.declinedSubmissionBody);
        }
        if (hasQuoteIssues) {
            return translator(messages[`quote${status}UWBody`]);
        }
        return translator(messages[`quote${status}Body`]);
    }, [isSubmissionLocked, status, isBMSI, hasQuoteIssues, translator, isTransactionPendingWithUW, productCode]);

    const continueSubmissionHeading = useMemo(() => {
        if (status === QUOTE_STATUS.DECLINED) {
            return translator(messages.declinedSubmission)
        }

        if (_.get(quote, 'productCode') === PRODUCT.COMMERCIAL_PACKAGE && status === QUOTE_STATUS.QUOTED) {
            return translator(messages.quotedSubmission);
        }

        return translator(messages.continueSubmissionHeader)
    }, [status, translator, quote])

    const downloadQuoteDocument = () => WMICDocumentUtil.downloadQuoteDocument(quote.jobNumber, authHeader);

    const continueQuoteLabel = hasQuoteIssues ? messages.editQuoteButton : messages.continueQuoteButton;
    const isSubmissionReadOnly = !WMICUserAccessUtil.canEditSubmission(currentUser.roles);

    const overrideProps = {
        pageHeaderContainer: {
            content: submissionData(),
        },
        continueSubmissionHeading: {
            title: continueSubmissionHeading,
            visible: quote?.statusCode !== QUOTE_STATUS.BOUND,
            isQuoteDownloadable:  isSubmissionLocked && quote?.productCode === PRODUCT.COMMERCIAL_PACKAGE && quote?.statusCode === QUOTE_STATUS.QUOTED && !hasQuoteBlockingUnderwritingIssues,
            downloadQuoteDocument
        },
        quoteLockedComponentContainer: {
            className: styles.wmicStatusContainerWrapper,
            visible: isSubmissionLocked && status === QUOTE_STATUS.QUOTED && !isSubmissionReadOnly
        },
        quoteReadOnlyComponentContainer: {
            visible: isSubmissionReadOnly
        },
        statusComponentContainer: {
            className: status === QUOTE_STATUS.DECLINED ? styles.wmicStatusContainerWrapperDeclined : styles.wmicStatusContainerWrapper
        },
        quoteLockedStatusIcon: {
            icon: 'mi-info',
            className: styles.wmicStatusIcon
        },
        statusMessageHeading: {
            content: statusMessageHeading
        },
        statusMessageBody: {
            content: statusMessageBody
        },
        uwiBrokerNotifierText: {
            visible: WMICQuoteUtil.isUWIBrokerNotifierMsgVisible(quoteVM, false) && unapprovedUWIssues?.length > 0,
            content: WMICRichTextUtil.translateRichText(translator(messages.uwiBrokerNotifierText, { broker_email: quoteVM?.uwIssueNotificationEmail?.value }))
        },
        statusIcon: {
            icon: getStatusIcon(),
            className: status === QUOTE_STATUS.DECLINED ? styles.wmicStatusIconDeclined : styles.wmicStatusIcon
        },
        premiumCostsCard: {
            visible: [QUOTE_STATUS.QUOTED, QUOTE_STATUS.BOUND].includes(quote?.statusCode),
            premium: _.get(quoteVM, 'quoteData.offeredQuotes.value[0].premium', {}),
            jobVM: quoteVM
        },
        actionButtonsContainer: {
            visible: !isSubmissionLocked && ![QUOTE_STATUS.WITHDRAWN, QUOTE_STATUS.DECLINED, QUOTE_STATUS.BOUND, QUOTE_STATUS.NOT_TAKEN].includes(quote?.statusCode)
        },
        UWIssuesRaisedContainer: {
            visible: unapprovedUWIssues?.length > 0
        },
        noOutstandingUWIssuesContainer: {
            visible: unapprovedUWIssues?.length === 0
        },
        autoApprovedUWIssuesContainer: {
            visible: autoApprovedUWIssues?.length > 0
        },
        UWIssuesRaisedSubTitle: {
            title: translator(messages.uwMustReviewTitle, {count: unapprovedUWIssues?.length})
        },
        raisedUWIssuesItems: {
            underwritingIssues : approvedUWIssues
        },
        approvedUWIssuesItems: {
            underwritingIssues: approvedUWIssues
        },
        autoApprovedUWIssuesItems:{
            underwritingIssues: autoApprovedUWIssues
        },
        underwritingIssuesCard: {
            visible: [QUOTE_STATUS.QUOTED, QUOTE_STATUS.BOUND].includes(quote?.statusCode),
        },
        quotePageSummary: {
            jobVM: quoteVM,
            visible: isSubmissionLocked && quote?.statusCode === QUOTE_STATUS.QUOTED && !hasQuoteBlockingUnderwritingIssues
        },
        quotePageUploadDocuments: {
            documents: userDocuments,
            updateDocuments: setUserDocuments,
            value: {
                documentDTOs: userDocuments,
                canUploadDocument: true,
                jobNumber: quoteVM?.quoteID.value
            },
            visible: isSubmissionLocked && quote?.statusCode === QUOTE_STATUS.QUOTED && !hasQuoteBlockingUnderwritingIssues
        },
        continueQuote: {
            content: continueQuoteLabel,
            visible: !isSubmissionReadOnly
        },
        withdrawQuote: {
            visible: WMICUserAccessUtil.canWithdrawSubmission(currentUser.roles)
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onWithdrawQuote: handleWithdrawQuote,
            onContinueQuote: hasQuoteIssues
            ? handleEditQuote
            : handleContinueQuote,
        },
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICPremiumCosts,
            WMICDocuments,
            WMICUnderwritingIssuesList
        }
    };

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (_.isEmpty(quote)) {
        return null;
    }

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

export default withRouter(withViewModelService(WMICQuoteDetailsPage));
