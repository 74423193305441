export default {
    frenchLangDisplayName: 'Français',
    englishLangDisplayName: 'English',
    frenchLocaleKey: 'fr',
    englishLocaleKey: 'en-US',
    quebecJurisdiction_EN: 'Quebec',
    quebecJurisdiction_FR: 'Québec',
    searchOptionTypes: [
        {
            className: 'jut__lookup__lookupOptionAccount',
            displayName: 'Account',
            icon: 'gw-account-circle',
            type: 'Account',
        },
        {
            className: 'jut__lookup__lookupOptionPolicy',
            displayName: 'Policy',
            icon: 'gw-description',
            type: 'Policy',
        },
        {
            className: 'jut__lookup__lookupOptionPolicy',
            displayName: 'Submission',
            icon: 'gw-description',
            type: 'Submission',
        }   
    ]
};
