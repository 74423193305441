
const CONSTANTS = {
    Person: 'Person',
    Company: 'Company',
    Employee: 'employee',
    cancel: 'cancel',
    UNAVAILABLE: 'unavailable',
    POLICY_CHANGE: 'policy change',
    RENEWAL: 'renewal',
    IN_FORCE: 'in force',
    IN_FORCE_FR: 'en vigueur',
    SCHEDULED: 'scheduled',
    CANCELED: 'canceled',
    EXPIRED: 'expired',
    EXPIRED_FR: 'expiré',
    NEW: 'new',
    RENEWING: 'renewing',
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    LIST_BILL: 'ListBill',
    FULL_PAY: 'Full Pay',
    FULL_PAY_CANADA: 'Full Pay (Canada)',
    TWO_PAY: 'Two Pay',
    THREE_PAY: 'Three Pay',
    MONTHLY_PAY: 'Monthly Automated Recurring',
    MONTHLY: 'monthly',
    FULL_PAYMENT: 'fullPayment',
    TWO_PAYMENTS: 'twoPayments',
    OVERDUE: 'overdue',
    PAYMENT_DUE: 'paymentDue',
    NEXT_PAYMENT: 'nextPayment',
    REVIEWED: 'reviewed',
    ERROR_MESSAGE: 'ErrorMessage: ',
    WATERCRAFT_TYPES: {
        PATH: 'watercraftType.value.code',
        OUTBOARDMOTOR: 'OutboardMotor',
        PERSONALWATERCRAFT: 'PersonalWatercraft',
        BOATEQUIPMENTTRAILER: 'BoatEquipmentTrailer'
    },
    QUOTED: 'Quoted',
    BOUND: 'Bound',
    SKIP_CANCEL_MODAL: ['knockoutpage', 'logout', 'summary'],
    SELECTED_ADDRESS: 'selectedAddress',
    SEARCH_TEXT: 'searchText',
    DEFAULT_SEARCH_KEY: 'search',
    OTHER: 'other',
    UNKNOWN: 'unknown',
    UNK: 'unk',
    METADATAMAP: 'metaDataMap',
    MULTIPLE: 'Multiple',
    SKELETON: 'skeleton',
    MAILING_ADDRESS:'mailing_wmic',
    PERSONAL_INFO_CONSENT_FORM: 'personalinfoconsentform',
    CREDIT_CONSENT_RECEIVED: 'creditconsentreceived',
    AMOUNT_DTO_NAME: "wmic.edge.common.capabilities.currency.dto.AmountDTO",
    POLICY_CLAIM_DTO_NAME: "wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.PolicyClaimPayment_WMICDTO",
    DRIVER_TYPES: {
        ASSIGNED: 'Assigned',
        UNASSIGNED: 'Unassigned',
        EXCLUDED: 'Excluded'
    },
    DRIVER_TYPE_WMIC: {
        PRINCIPAL: 'principal',
        OCCASIONAL: 'occasional'
    },
    ASSIGNMENT: {
        UNASSIGNED: 'Unassigned',
        RESTRICTED: 'Restricted',
        EXCLUDED: 'Excluded',
        ASSIGNED: 'Assigned'
    },
    REASON: {
        OTHER: 'Other'
    },
    OCCUPATION_TYPES: {
        PA_QC: 'PAOccupationTypesQC',
        GUI_SELECTABLE: 'GUI_Selectable'
    },
    VENDOR_TYPES: {
        INDEPENDENT: 'independent',
        FACILITY: 'repair_facility'
    },
    REPAIR_STATUS: {
        ESTIMATING: 'estimating',
        REPAIRING: 'repairing'
    },
    INCLUDED_PAYMENT_STATUS: {
        VOIDED: 'voided'
    },
    PAYMENT_DETAIL_STATUS: {
        ISSUED: 'Issued',
        VOIDED: 'Voided',
        DEPOSITED: 'Deposited',
        CASHED: 'Cashed'
    },
    CLAIM_STATUS: {
        DRAFT: 'draft',
        OPEN: 'open',
        CLOSED: 'closed',
        ARCHIVED: 'archived'
    },
    LICENSE_TYPES: {
        FOREIGN_LICENSE: 'FOREIGN_LICENSE',
        INTERNATIONAL_DRIVER_PERMIT: 'INTERNATIONAL_DRIVER_PERMIT'
    },
    BOT_PARAM_CONFIG: {
        MINIMUM_LEGAL_AGE: 0, // used to calculate the maximum datepicker date
        MAXIMUM_LEGAL_AGE: 500 // used to calculate the minimum datepicker date
    },
    GOLD_LIST_PAGE_SIZE: 10,
    COVERAGE_NAMES: {
        PA_COMPREHENSIVE: 'PA_ComprehensiveCov',
        PA_COLLISION: 'PA_CollisionCov',
        PA_RENTAL: 'PA_RentalExpCov',
        PA_VMRP: 'PA_VMRPCov',
        PA_UNIMSPD: 'PA_UNIMSPDCov',
        PA_UNIMSCOLL: 'PA_UNIMSCollCov',
        PA_UMCOLLISIONWAIVER: 'PA_UMCollisionCovWaiver',
        PA_SVE: 'PA_SVECov',
        PA_ROADASSIST: 'PA_RoadAssistCov',
        PA_UMPROPERTYWAIVER: 'PA_UMPropertyCovWaiver',
        PA_BI: 'PA_BICov',
        PA_PD: 'PA_PDCov',
        PA_PIP: 'PA_PIPCov',
        PA_MEDPAY: 'PA_MedPayCov',
        PA_UNIMSBI: 'PA_UNIMSBICov',
        PA_UMWAIVER: 'PA_UMCovWaiver',
        HO_PACKAGE: 'HO_PackageCov',
        HO_FORMTYPE: 'HO_FormType',
        HO_PERSONALPROPERTYREPLACEMENTCOSTSETTLEMENT: 'HO_PersonalPropertyReplacementCostSettlementCov',
        HO_DWELLING: 'HO_DwellingCov',
        HO_OTHERSTRUCTURES: 'HO_OtherStructuresCov',
        HO_PERSONALPROPERTY: 'HO_PersonalPropertyCov',
        HO_PERSONALPROPERTY_LMT: 'HO_PersonalPropertyCovLmt',
        HO_ADDLLIVINGEXP: 'HO_AddlLivingExpCov',
        HO_PERSONALLIABILITY: 'HO_PersonalLiabilityCov',
        HO_MEDICALPAYMENTS: 'HO_MedicalPaymentsCov',
        HO_BUSINESSPURSUITSCLERICAL: 'HO_BusinessPursuitsClericalCov',
        HO_BUSINESSPURSUITSSALESPERSON: 'HO_BusinessPursuitsSalespersonCov',
        HO_BUSINESSPURSUITSTEACHER: 'HO_BusinessPursuitsTeacherCov',
        HO_BUSINESSPURSUITSOTHER: 'HO_BusinessPursuitsOther',
        HO_CREDITCARD: 'HO_CreditCardCov',
        HO_EARTHQUAKE: 'HO_EarthquakeCov',
        HO_HOMECYBERPROTECTION: 'HO_HomeCyberProtection',
        HO_SPECIALLIMITSENHANCEMENT: 'HO_SpecialLimitsEnhancement',
        HO_HOMESYSTEMSPROTECTION: 'HO_HomeSystemsProtection',
        HO_IDENTITYFRAUDEXPENSE: 'HO_IdentityFraudExpense',
        HO_INCRLMTBUSPROPERTY: 'HO_IncrLmtBusPropertyCov',
        HO_LOSSASSESSMENT: 'HO_LossAssessCov',
        HO_SERVICELINE: 'HO_ServiceLineCov',
        HO_WILDFIRERESPONSE: 'HO_WildfireResponse',
        HO_INCRSPECLMTOFLIAB: 'HO_IncrSpecLmtOfLiabCov',
        HO_PERMINCOCCRES: 'HO_PermIncOccResPremCov',
        HO_OTHERSTRUCTURESENDORSEMENT: 'HO_OtherStructuresEndorsementCov',
        HO_WATERCRAFTMOTOR_WMIC: 'HOWatercraftMotor_WMIC',
        HO_SCHEDULEDPERSONALPROPERTY: 'HO_SchedPersPropCov',
        PERSONAL_PROPERTY: 'Personal Property',
        PERSONAL_AUTOMOBILE: 'Automobile',
        PERSONAL_UMBRELLA: 'Personal Umbrella',
        ADDITIONAL_LIVING_EXPENSES: 'Additional Living Expenses',
        PERSONAL_LIABILITY: 'Personal Liability',
        MEDICAL_PAYMENTS: 'Medical Payments',

    },
    COVERAGE_TERM_NAMES: {
        PA_RENTALEXPCOVLIMIT: 'PA_RentalExpCovLmt',
        PA_COLLISIONCOVRENTALEXPLIMIT: 'PA_CollisionCovRentalExpLmt',
        PA_COMPREHENSIVECOVRENTALEXPLIMIT: 'PA_ComprehensiveCovRentalExpLmt',
        PA_COLLISIONCOVDED: 'PA_CollisionCovDed',
        PA_COMPREHENSIVECOVDED: 'PA_ComprehensiveCovDed'
    },
    VEHICLE_PRIMARY_USE: {
        PLEASURE: 'pleasure',
        BUSINESS: 'business',
        COMMUTING: 'commuting',
        TOURING: 'Touring',
        PARK_RIDE: 'park_ride',
        FARM: 'Farm_WMIC',
        CLERGYMAN: 'Clergyman_WMIC'
    },
    NO_DEDUCTIBLE: 'No Deductible',
    JURISDICTIONS: {
        CA: 'CA',
        OR: 'OR'
    },
    POLICY_TYPES: {
        HO: {
            HOMEOWNERS: 'homeowners',
            HOMEOWNERS_CAPITAL: 'Homeowners',
            HOMEOWNERS_HOE: 'HomeownersLine_HOE',
            HOMEOWNER: 'homeowner',
            TENANT: 'tenant',
            RENTERS: 'renters',
            CONDOMINIUM: 'condominium'
        }
    },
    VEHICLE_TYPES: {
        TRAILER: 'trailer',
        CAMPER: 'camper',
        AUTO: 'auto',
        MOTORHOME: 'motorhome',
    },
    QUESTION_TYPES: {
        BOOLEAN: 'Boolean',
        INTEGER: 'Integer'
    },
    CONFIG_PERSONA: {
        PRODUCER: 'producer',
        CSR: 'csr'
    },
    RESIDENCE_TYPES: {
        SINGLE_FAMILY: 'SingleFamily_wmic'
    },
    DEVICE_TYPES: {
        ANDROID: 'android',
        IOS: 'ios'
    },
    FORM_TYPES: {
        PERSONAL_AUTO_POLICY_DECLARATION: 'PADEC',
        HOMEOWNERS_POLICY_DECLARATION: 'HODEC',
        PERSONAL_UMBRELLA_POLICY_DECLARATION: 'PUPDEC',
        COMMERCIAL_PACKAGE_POLICY_DECLARATION: 'CPDEC',
        IDENTIFICATION_CARD: 'FRC',
        CANCELLATION_MEMO: 'CANCELLATION_WMIC',
    },
    DOCUMENT_TYPE: {
        VID: 'VID',
        PADEC: 'PADEC',
        HODEC: 'HODEC',
        CADCF: 'CADCF',
        CADCFA: 'CADCFA',
        ORDCF: 'ORDCF',
        ORDCFA: 'ORDCFA',
        ORRA: 'ORRA',
        CARA: 'CARA'
    },
    DOCUMENT_PATTERNS: {
        STATEMENT_OF_ACCOUNT: 'StatementOfAccount',
        OFFER_OF_EARTHQUAKE: 'OfferOfEarthquake'
    },
    DOCUMENT_DEFAULTS: {
        MAX_NUM_OF_FILES: 10,
        MAX_FILE_SIZE_KB: 51200 // 50 MB, 51200KB
    },
    ERROR: 'error',
    CONTACT_ROLES: {
        ACCOUNT_HOLDER: 'AccountHolder',
        ADDITIONAL_INTEREST: 'Additional Interest'
    },
    PHONE_TYPES: {
        HOME: 'home',
        WORK: 'work',
        MOBILE: 'mobile',
        NO_PHONE_WMIC: 'nophone_wmic'
    },
    PHONE_TYPE_PATHS: {
        HOME: "homeNumber",
        WORK: "workNumber",
        MOBILE: "cellNumber"
    },
    PAYMENT_METHODS: {
        ONE_TIME_WITHDRAWAL: 'OneTimeWithdrawal'
    },
    INTEGRATION_CC_PAYMENT_STATUS: {
        COMPLETE: 'complete',
        ERROR: 'error'
    },
    FINANCE_LEASING: {
        CHANGE_TYPE_ADD: 'ADD',
        CHANGE_TYPE_CHANGE: 'CHANGE',
        CHANGE_TYPE_SEND: 'SEND',
        CHANGE_TYPE_DELETE: 'DELETE',
    },
    COUNTRY: {
        US: 'US',
        US_LONG: 'UnitedStates',
        CA: 'CA'
    },
    STATE: {
        CA: 'CA',
        OR: 'OR',
        CA_LONG: 'california',
        OR_LONG: 'oregon',
        AB: 'AB'
    },
    LOB_STATUSES: {
        EXPIRED: 'expired',
        CANCELLED: 'canceled',
        IN_FORCE: 'in force',
        SCHEDULED: 'scheduled'
    },
    NOTICE_TYPE: {
        NONE: 'none',
        AMP: 'amp',
        CLAIMS: 'claims',
        CARD: 'card',
        BANK: 'bank',
        MANAGEBANKACCOUNTS: 'manageBankAccounts',
        PAYMENTOPTIONS: 'paymentoptions',
        CUSTOMEREVENTS: 'customerevents',
        POLICYCHANGE: 'policychange',
        FUTUREDATEDOTWPAYMENTS: 'futuredatedotwpayments',
        ASSISTEDPOLICYCHANGE: 'enableWebPolicyChangeForms',
        CLAIMSFNOL: 'enableClaimsFNOL',
        EMQ: 'enableEMQ',
        WALLETPASS: 'walletpass'
    },
    CENTRAL: 'central',
    NONE: 'none',
    POLICY_CHANGE_TYPE: {
        SELECT_TYPE: 'select-type',
        CHANGE_ADDRESS: 'change-address',
        PAYMENT_PLAN: 'payment-plan',
        FINANCE_LEASING: 'finance-leasing',
        FINANCE_LEASING_ADD: 'finance-leasing-add',
        FINANCE_LEASING_CHANGE: 'finance-leasing-change',
        FINANCE_LEASING_DELETE: 'finance-leasing-delete',
        FINANCE_LEASING_SEND: 'finance-leasing-send',
        DELETE_VEHICLE: 'delete-vehicle',
        ROADSIDE_ASSISTANCE: 'roadside-assistance',
        ROADSIDE_ASSISTANCE_ADD: 'roadside-assistance-add',
        ROADSIDE_ASSISTANCE_DELETE: 'roadside-assistance-delete',
        ADJUST_COVERAGES: 'adjust-coverages',
        OTHER_CHANGES: 'other-changes',
        DESIGNATED_PERSON: 'designated-person',
        DESIGNATED_PERSON_ADD: 'designated-person-add',
        DESIGNATED_PERSON_CHANGE: 'designated-person-change',
        DESIGNATED_PERSON_DELETE: 'designated-person-delete',
        EDIT_POLICY_SELF_SERVICE: 'edit-policy-self-service',
        REVIEW_POLICY_CHANGE: 'review-policy-change',
        BOUND_POLICY_CHANGE: 'bound-policy-change',
        EDIT_UNINSURED: 'edit-uninsured',
        BODILY_INJURY: 'bodily-injury',
        SUCCESS_PAGE: 'success-page'
    },
    PRIMARY_NAMED_INSURED: 'Primary Named Insured',
    PRIMARY_NAMED_INSURED_CAMEL_CASE: 'primaryNamedInsured',
    ADD_NAMED_INSURED_CAMEL_CASE: 'addlNamedInsured',
    DAP_PERSON_CAMEL_CASE: 'dapPerson_WMIC',
    PAYMENT_TYPES: {
        CREDIT_CARD: 'creditcard',
        WIRE: 'wire',
        REDISTRIBUTE: 'redistribute',
        NONE: 'none'
    },
    FNOL: {
        DRIVER_VALES: {
            OUR_INSURED: 'OurInsured',
            NO_DRIVER: 'NoDriver',
            OTHER_PARTY: 'OtherParty',
            UNKNOWN: 'Unknown'
        },
        VARIANTS: {
            INSURED: 'insured',
            OTHER_PARTY: 'otherParty',
            DRIVER: 'driverOfInsuredVehicle'
        },
        SHARED_DATA_PATHS: {
            INSURED: 'insured',
            OTHER_PARTY_CLAIMANT: 'otherPartyClaimant'
        },
        YOUR_INFORMATION: {
            INSURED: 'INSURED',
            OTHER_PARTY: 'OTHER_PARTY'
        }
    },
    POSTAL_CODE_REGEX: {
        // Keep regex in sync with PC
        // This is used as the default pattern for postal codes in the @jutro/Address component
        // modules\configuration\config\geodata\CA\address-config.xml
        CA: '(^(?!.*[DFIOQUdfioqu])[A-VXYa-vxy][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$)'
    },
    POSTAL_CODE_MASKS: {
        US_CODE: '99999',
        US_CODE_LONG: '99999-9999',
        CA_CODE: 'a9a 9a9'
    },
    INSURED_ROLES: {
        PRIMARY_INSURED: 'primaryInsured',
        ADDITIONAL_INSURED: 'additionalInsured'
    },
    CREDIT_CONSENT_CODES: {
        YES: 'yes',
        NO: 'no',
        WITHDRAWN: 'withdrawn',
        NO_DISTINCT_FROM_WITHDRAWN: 'no_distinct_from_withdrawn'
    },
    MODAL_RESULT: {
        CONFIRM: 'confirm',
        CANCEL: 'cancel',
        CLOSE: 'close'
    },
    FUTURE_RENEWAL_MODAL_RESULT: {
        CURRENT_AND_RENEWING: 'currentAndRenewing',
        CURRENT_ONLY: 'currentOnly'
    },
    TERM_TYPES: {
        ANNUAL: 'Annual',
        HALF_YEAR: 'HalfYear'
    },
    PRIMARY_INSURED_RELATIONSHIP: {
        INSURED: 'insured'
    },
    ADDITIONAL_INTEREST_TYPES: {
        ADDITIONAL_INSURED: 'ADDINSURED_WMIC',
        CERT_HOLDER: 'CERTHOLDER',
        CHATTEL_MORTGAGEE: 'CHATTELMORTGAGEE_WMIC',
        LOSS_PAYEE: 'LOSSP',
        MORTGAGEE: 'MORTGAGEE'
    },
    HEATING_FUEL_TYPES: {
        WOOD: 'wood',
        OIL: 'oil',
        ANTHRACITE: 'anthracite',
        PELLET: 'pellet',
        UNKNOWN: 'unknown'
    },
    HEATING_TYPES: {
        COMBINATION_FURNACE: 'combinationfurnace',
        OUTDOOR_FURNACE: 'outdoorfurnace',
        STOVE: 'stove',
        SPACE_HEATER_NON_PORTABLE: 'spaceheaternonportable',
        HEAT_PUMP: 'heatpump',
        HEATED_FLOOR_SLAB: 'heatedfloorslab',
        MINISPLIT: 'minisplit_wmic',
        NONE: 'none'
    },
    HEATER_TYPES: {
        PRIMARY: 'primary',
        AUXILIARY: 'auxiliary'
    },
    BUSINESS_SEGMENT_CODES: {
        PERSONAL: 'Personal',
        COMMERCIAL: 'Commercial'
    },
    PRIMARY_VEHICLE_USES: {
        COMMUTING: "commuting",
        BUSINESS: "business"
    },
    DANGEROUS_GOOD_TYPES: {
        CRUDEOILANDORCRUDEOILCONDENSATE: "CrudeOilAndOrCrudeOilCondensate",
        OTHER: "Other",
        COMPRESSEDGAS: "CompressedGas",
        EXPLOSIVES: "Explosives"
    },
    HO_SCHEDULED_ITEM_COV_TYPES: {
        VACATION_TRAILER: 'HO_VacationTrailerCov',
        MISCELLANEOUS: 'Miscellaneous_wmic',
        MOTORIZED_VEHICLE: 'HO_MotorizedVehicleCov',
        FINE_ART: 'HO_FineArtsCov',
        COMPUTER: 'HO_ComputerCov',
        HORSE: 'HO_HorseCov',
        PERSONAL_ARTICLES: 'HO_PersonalArticlesCov',
        SPORTS_EQUIPMENT: 'HO_SportsEquipmentCov',
        BICYCLE: 'HO_BicycleCov',
        TOOLS: 'HO_ToolsCov'
    },
    HO_SCHEDULED_ITEM_SUBTYPES: {
        CAMERAS: 'Cameras_CA_WMIC',
        CAMERAS_AND_ACCESSORIES: 'CamerasandAccessories_CA_WMIC',
        MUSICAL: 'Musical_CA_WMIC',
        CELL_PHONE: 'CellularPhone_CA_WMIC',
        JEWELRY: 'Jewelry_CA_WMIC',
        RPC_1348_ATV: 'MV_ATVs_CA_WMIC'
    },
    POLICY_NUMBER_MATCHES_CURRENT_POLICY: 'Policy number matches current policy',
    INCIDENT_TYPES: {
        PROHIBITION: 'Prohibition_WMIC'
    },
    POLICYCHANGEDIFF_NODETYPE: {
        ADD: "Add",
        REMOVE: "Remove",
        CHANGE: "Change",
        TITLE: "Title",
    },
    POLICY_ADDL_INTEREST: 'PolicyAddlInterest',
    POLICY_ADDL_INSURED: 'PolicyAddlInsured',
    CP_BUILDING_ADDL_INTEREST: 'CPBldgAddlInterest',
    CPIM_MCEQUIP_PART_ADDL_INTEREST: 'CPIMMCEquipPartAddlInterest_WMIC',
    CPIM_TOOLS_PART_ADDL_INTEREST: 'CPIMToolsPartAddlInterest_WMIC',
    CP_BUILDING_NO_ALARM: 'NoAlarm',
    CP_BUILDING_NO_MONITORING: 'NoMonitoring',
    CP_BUILDING_NO: 'No',
    CANCELLED_ERROR: 'CanceledError',
    CANCELLATION_REFUND_METHODS: {
        SHORT_RATE: 'shortrate',
        PRO_RATE: 'prorate'
    },
    RENEWAL_SKIP_CANCEL_MODAL_URLS: ['change', 'contactAgent', 'summary'],
    YES_NO_WMIC_CODES: {
        YES: 'Yes'
    },
    ADDRESS_VALIDATION_STATUS: {
        INVALID: 'invalid',
        NEW: 'new',
        OVERRIDDEN: 'overridden',
        UNAVAILABLE: 'unavailable',
        SUGGESTED: 'corrected',
        VALID: 'valid'
    },
    INVOICE_PAID_STATUS: {
        FULLY_PAID: 'fullypaid',
        PARTIALLY_PAID: 'partiallypaid',
        UNPAID: 'unpaid'
    },
    HIGH_HAZARD_OPERATION: {
        DANGEROUS_GOODS: 'dangerous_goods',
        LIVESTOCK: 'livestock',
        LOGS: 'logs',
        LUMBER: 'lumber',
        MOVING_AND_STORAGE: 'moving_and_storage',
        NO: 'no',
        NON_HAZARDOUS_BULK_LIQUIDS: 'non_hazardous_bulk_liquids',
        OIL_FIELD: 'oil_field',
        OVERSIZED_LOAD: 'oversize_load',
        SAND_STONE_GRAVEL_AND_EARTH: 'sand_stone_gravel_and_earth',
        WINTER_ICE_ROAD: 'winter_ice_road'
    },
    INTERNAL_UNDERWRITING_ROLES: {
        UNDERWRITER_CANADA: 'Underwriter (Canada)',
        UNDERWRITING_ASSISTANT_CANADA: 'Underwriting Assistant (Canada)',
        UNDERWRITING_MGMT_CANADA: 'Underwriting Management (Canada)',
        EXECUTIVE_UNDERWRITING_CANADA: 'Executive Underwriting (Canada)'
    },
    ANIS_PATH: 'baseData.additionalNamedInsureds_WMIC',
    HOUSEHOLD_MEMBERS_PATH: 'lobData.personalUmbrella.coverables.householdMembers'
};

export default CONSTANTS;

/**
 * Flow Step for Validation
 * @readonly
 * @enum {String}
 */
export const FlowStepId = {
    POLICY_DETAILS: 'PolicyDetails',
    PRE_QUALIFICATION: 'PreQualification',
    PA_DRIVERS: 'PADrivers',
    PERSONAL_VEHICLES: 'PersonalVehicles',
    PA_COVERAGES: 'PACoverages',
    PA_INSURANCE_HISTORY: 'PAInsuranceHistory',

    HO_RISKS: 'HORisks',
    HO_CONSTRUCTION: 'HOConstruction',
    HO_SCHEDULED_ITEMS: 'HOScheduledItems',
    HO_COVERAGES: 'HOCoverages',
    HO_INSURANCE_HISTORY: 'HOInsuranceHistory',

    PUP_UNDERLYINGPOLICIES: "UnderlyingPolicy",
    PUP_HOUSEHOLDMEMBERS: "HouseHoldMember",

    CP_OPERATIONS: "CPOperations",
    CP_LOCATIONBUILDINGS: "CPLocationBuildings",
    CP_PROPERTY: "CPProperty",
    CP_LIABILITY: "CPLiability",
    CP_ADDITIONALCOVERAGES: "CPAdditionalCoverages",
    CP_INSURANCE_HISTORY: "CPInsuranceHistory"
};

/**
 * Validation Levels
 * @readonly
 * @enum {String}
 */
export const ValidationLevel = {
    LOAD_SAVE: 'loadsave',
    DEFAULT: 'default',
    QUOTABLE: 'quotable',
    BINDABLE: 'bindable',
    READY_FOR_ISSUE: 'readyforissue',
    QUICK_QUOTABLE: 'quickquotable'
};

/**
 * Element position
 * @readonly
 * @enum {String}
 */
export const Position = {
    TOP: 'top',
    LEFT: 'left',
    RIGHT: 'right',
    BOTTOM: 'bottom'
};

/**
 * Device Breakpoints
 * @readonly
 * @enum {String}
 */
export const DeviceBreakpoint = {
    DESKTOP: 'desktop',
    PHONE: 'phone',
    TABLET: 'tablet'
};

/**
 * @readonly
 * @enum {String}
 */
export const LOBConstants = {
    PA: 'PersonalAuto',
    PUP: 'PersonalUmbrella',
    PP: 'Homeowners',
    HOME: 'home',
    PERSONAL_PROPERTY: 'PersonalProperty',
    CP: 'CommercialPackage',
    PA_LINE: 'PersonalAutoLine',
    PP_LINE: 'HomeownersLine_HOE',
    PUP_LINE: 'PersonalUmbrellaLine_PUE',
    CP_LINE: 'CPLine',
    GL_LINE: 'GeneralLiabilityLine',
    PA_PATH: 'personalAuto'
};


export const PUP_LOB_CODES = {
    PA_CODE: 'PA',
    HO_CODE: 'HO',
    PWL_CODE: 'PWL'
}

export const ACTION_TYPES = {
    ADD: 'add',
    REMOVE: 'remove',
    EDIT: 'edit',
    NONE: 'none',
    VIEW: 'view'
};

export const APP_NAMES = {
    CE: 'customer-engage',
    PE: 'producer-engage',
    AMP: 'account-management'
};

export const FORMAT_CODES = {
    myWork: 'myWork',
    FOR_CURRENT_USER: 'FOR_CURRENT_USER',
    all: 'all',
    ALL: 'ALL',
    BY_PRODUCER_CODES: 'BY_PRODUCER_CODES'
};

export const ICON_NAMES = {
    ICON_USER: 'fa-user',
    ICON_CAR : 'mi-directions-car',
    ICON_BUILDING: 'mi-home',
    ICON_UMBRELLA: 'fa fa-umbrella fa-fw',
    ICON_EDIT: 'mi-edit',
    ICON_SHIP: 'mi-directions-boat',
    ICON_COMMERCIAL: 'mi-storefront',
    ICON_INFO: 'mi-info'
};

export const POLICY_DETAILS = {
    PROVINCES_WITH_LANGUAGE_PREFERENCE: ['NB', 'NS', 'PE', 'QC'],
    PROVINCES_WITH_LANGUAGE_PREFERENCE_EXCEPT_AUTO: ['BC', 'AB', 'SK', 'MB', 'ON', 'YT'],
    BLUEPASS_HO_SUPPORTED_JURISDICTIONS: ['BC', 'AB', 'SK', 'MB', 'ON', 'QC', 'YT', 'NS', 'PE', 'NB'],
    BLUEPASS_SUPPORTED_JURISDICTIONS_EXCEPT_QC: ['BC', 'AB', 'SK', 'MB', 'ON', 'YT', 'NS', 'PE', 'NB'],
    DRIVER_CLASS_STATE_CODES: ['BC', 'MB', 'NB', 'NL', 'NS', 'NU', 'NT', 'PE', 'QC', 'SK'],
    DRIVER_TRAINING_COURSE_CLASS_STATE_CODES: ['AB', 'YT', 'ON', 'NB', 'NS', 'PE'],
    FRENCH: 'french',
    SNOW: 'snow',
    NO: 'no',
    YES: 'yes',
    NOTRELATED: 'notrelated',
    AGE_ALLOWED_TO_DRIVER: 14,
    MINIMUM_LEGAL_AGE: 0,
    MAXIMUM_LEGAL_AGE: 500
};

export const JURISDICTIONS = {
    MARITIMES: ['NB', 'NS', 'PE'],
    NEW_BRUNSWICK: 'NB',
    NOVA_SCOTIA: 'NS',
    ONTARIO: 'ON',
    PRINCE_EDWARD_ISLAND: 'PE',
    QUEBEC: 'QC',
    ALBERTA: 'AB',
    YUKON: 'YT',
    MANITOBA: 'MB',
}

export const JURISDICTION_DISPLAYNAMES = {
    QC_V1: 'Quebec',
    QC_V2: 'Québec'
}

export const RIDE_SHARING_COMPANIES = {
    URIDE: 'uride',
    LYFT: 'lyft',
    REDRIDE_KARI: 'redride_kari'
}

export const WATERCRAFT_TYPES = {
    PATH: 'watercraftType.value.code',
    OUTBOARDMOTOR: 'OutboardMotor',
    PERSONALWATERCRAFT: 'PersonalWatercraft',
    BOATEQUIPMENTTRAILER: 'BoatEquipmentTrailer'
};

export const BOAT_TYPES = {
    PATH: 'boatType.value.code',
    SAILBOAT: 'Sailboat',
    OTHER: 'Other'
};

/**
 * @readonly
 * @enum {String}
 */
export const AddressType = {
    HOME: 'home',
    BUSINESS: 'business'
};

/**
 * @readonly
 * @enum {String}
 */
export const JobType = {
    SUBMISSION: 'Submission',
    POLICY_CHANGE: 'PolicyChange',
    RENEWAL: 'Renewal',
    RENEWAL_FR: 'Renouvellement',
    CANCELLATION: 'Cancellation'
};

export const SUITES = {
    PC: 'pc',
    BC: 'bc',
    CC: 'cc'
};

export const AUTHORITY_TYPE = {
    ACCOUNT: 'ACCOUNT',
    POLICY: 'POLICY'
};

/**
 * @readonly
 * @enum {String}
 */
export const DwellingUsageType = {
    primary: 'prim',
    rental: 'rent'
};

/**
 * @readonly
 * @enum {String}
 */
export const ERROR_CODE = {
    CLUE_REPORT: 'CLUEReport',
    PAYMENT: 'PaymentError',
    AUTHORIZATION: 'AuthorizationError',
    DAY_TRANSITION: 'DayTransitionError',
    AVAILABILITY: 'Availability',
    TWELVE_PAY_BIND: 'TwelvePayBindError',
    INVALID_SESSION: 'InvalidSession',
    INVALID_SUB_SESSION: 'Invalid Submission Session Exception',
    PORTAL_VALIDATION: 'PortalValidation',
    DECLINE: 'Decline',
    UW_ISSUE: 'UWIssue',
    TECHNICAL_ERROR_RETRIEVE_QUOTE: 'TechnicalErrorRetrieveQuote',
    TECHNICAL_ERROR_CREATE_QUOTE: 'TechnicalErrorCreateQuote',
    TECHNICAL_ERROR_UPDATE_QUOTE: 'TechnicalErrorUpdateQuote',
    TECHNICAL_ERROR: 'TechnicalError',
    GW_UNDERWRITING_ERROR: 'GW_UNDERWRITING_ERROR'
};

/**
 * @readonly
 * @enum {String}
 */
export const PaymentPlanID = {
    FULL_PAY: 'bc:2001',
    THREE_PAY: 'bc:2002',
    TWELVE_INSTALLMENT: 'bc:2005'
};

/**
 * @readonly
 * @enum {String}
 */
export const PAYMENT_METHOD = {
    CREDIT_CARD: 'creditcard',
    WIRE: 'wire',
    AUTO_RECURRING: 'autorecurring_wmic',
    AUTO_RECURRING_ACCOUNT_INFO: 'autorecurring_account_info_wmic'
};

export const FEATURE_FLAG_NAMES = {
    pc8740: 'usepc8740',
    marp: 'marp',
    recaptchaV3: 'recaptchaV3',
    testOutageFlag: 'testOutage', // for testing purposes only
    FNOL: 'enable-fnol',
    AMP: 'enable-amp',
    CLAIMS: 'enable-claims',
    CARD: 'enable-card',
    BANK: 'enable-bank',
    CUSTOMEREVENTS: 'enable-customerevents',
    DASHBOARDCONSENT: 'enable-amp-dashboardconsent',
    EMQ: 'enable-emq',
    FUTUREDATEDOTWPAYMENTS: 'enable-futuredatedotwpayments',
    MANAGEBANKACCOUNTS: 'enable-managebankaccounts',
    POLICYCHANGE: 'enable-policychange',
    ASSISTEDPOLICYCHANGE: 'enable-enablewebpolicychangeforms',
    LOGGINGCSRF: 'enable-logging-csrf',
    WALLETPASS: 'enable-walletpass',
    CLAIMSDEDUCTIBLE: 'enable-claims-deductible',
    CLAIMSADJUSTERS: 'enable-claims-adjusters',
    CLAIMSREPAIRFACILITY: 'enable-claims-repair-facility',
    CLAIMSPAYMENTS: 'enable-claims-payments',
    CLAIMSRENTALS: 'enable-claims-rentals',
    CLAIMSDOCUMENTS: 'enable-claims-documents',
    CTA: 'enable-call-to-action',
    POLICYPLANCHANGETOMAR: 'enable-policyplanchangetomar',
    MANAGEBANKACCOUNTSINPROFILE: 'enable-managebankaccountsinprofile',
    LOGGINGTOSPLUNK: 'enable-logging-to-splunk'
};

/**
 * @readonly
 * @enum {String}
 */
export const ESIGNATURE_STATUS = {
    SIGNED: 'Signed',
    OPTIMISTICALLY_SIGNED: 'OptimisticallySigned',
    PENDING_SIGNATURE: 'PendingSignature',
    EXPIRED: 'Expired',
    DECLINED: 'Declined',
    CANCELLED: 'Cancelled',
};

export const RETRIEVE_AVAILABLE_MESSAGE = {
    QUOTE_NOT_FOUND: 'QuoteNotFound',
    QUOTE_DECLINED: 'QuoteDeclined',
    QUOTE_EXPIRED: 'QuoteExpired',
    UNDERWRITING_ISSUE: 'UnderwritingIssue',
    UNDERWRITER_REVIEW: 'UnderwriterReview',
    QUOTE_NOT_VALID: 'QuoteNotValid',
    QUOTE_NOT_TAKEN: 'QuoteNotTaken',
    PORTAL_VALIDATION: 'PortalValidation'
};

/**
 * @readonly
 * @enum {String}
 */
export const EMQ_USAGE_TYPES = {
    COMMUTING: 'Commuting',
    COMMUTING_LONG: 'Commute to or from work / school',
    PLEASURE: 'Pleasure',
    BUSINESS: 'Business'
};

/**
 * @readonly
 * @enum {String}
 */
export const EMQ_VEHICLE_PROPS = {
    PRIMARYUSE: 'PrimaryUse',
    VIN: 'Vin',
    VEHICLENAME: 'VehicleName',
    VEHICLENUMBER: 'VehicleNumber',
    ODOMETERREADING: 'OdometerReading',
    ANNUALMILEAGE: 'AnnualMileage',
    COMMUTINGMILES: 'CommutingMiles',
    DAYSPERWEEKCOMMUTE: 'DaysPerWeekCommute'
};

export const REFERRAL_WHITELIST_CODES = {
    ADVERTISING: 'advertising',
    FAMILY_FRIENDS: 'familyfriends',
    WAWANESA_CUSTOMER: 'wawanesa_customer',
    DEALERSHIP: 'dealership',
    ACCIDENT_CUSTOMER: 'accident_customer',
    ANOTHER_COMPANY: 'another_company',
    CCCU: 'cccu',
    OTHER: 'other'
};

/**
 * @readonly
 * @enum {String}
 */
export const EMQ_RESPONSE_TYPES = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    POLICY_NOT_FOUND: 'POLICY-NOT-FOUND',
    AMQ_NOT_REQUIRED: 'AMQ-NOT-REQUIRED'
};

/**
 * @readonly
 * @enum {String}
 */
export const EMQ_STEPS = {
    VEHICLE_INFO: 'vehicles-info',
    SUCCESS: 'success',
    NOT_AVAILABLE: 'not-available',
    TECHNICAL_ERROR: 'technical-error'
}

/**
 * @readonly
 * @enum {String}
 */
export const PAYMENT_STATUS = {
    DONE: 'DONE',
    BOUND: 'BOUND',
    WAIT: 'WAIT',
    PAID_WITH_EXCEPTION: 'PAIDWITHEXCEPTION'
};

/**
 * @readonly
 * @enum {String}
 */
export const MVR_INCIDENT_CODE = {
    CONVICTION: 'CONV',
    SUSPENSION: 'SUSP',
    PROHIBITION: 'PROH_WMIC',
    RESTRICTION: 'REST_WMIC'
};

/**
 * @readonly
 * @enum {String}
 */
export const MVR_ACTIONS = {
    NONE: 'none',
    ADD: 'add',
    EDIT: 'edit',
    VIEW: 'view'
};

/**
 * @readonly
 * @enum {String}
 */
export const MVR_CONSTANTS = {
    DAYS_BACK_MVR_DATE: 59,
    PREDICTOR_ONLY: 'predictoronly',
    FULL_REPORT: 'fullreport',
};

/**
 * @readonly
 * @enum {String}
 */
export const POLICY_HISTORY_ACTIONS = {
    ADD: 'add',
    EDIT: 'edit'
};

/**
 * @readonly
 * @enum {String}
 */
export const LOSS_HISTORY_ACTIONS = {
    ADD: 'add',
    EDIT: 'edit'
};

/**
 * @readonly
 * @enum {String}
 */
export const DASH_REPORTS_CONSTANTS = {
    PATH: 'dashReports_Ext',
    VALUE: 'dashReports_Ext.value',
    CHILDREN: 'dashReports_Ext.children',
}

/**
 * @readonly
 * @enum {String}
 */
export const LOSS_HISTORY_STATUS = {
    ORDERED: 'Ordered',
    RECEIVED: 'Received',
    REQUESTED: 'Requested',
    ERROR: 'Error',
    CANCELLED: 'Cancelled'
}

/**
 * @readonly
 * @enum {String}
 */
export const SOURCE_SYSTEM = {
    GPA: 'GPA',
    MANUAL: 'Manual',
}

export const LOADING = {
    ACTIVE: 0b00,
    PASSIVE: 0b01,
    ON: 0b10,
    OFF: 0b00
}

/**
 * @readonly
 * @enum {String}
 */
export const LINE_OF_BUSINESS = {
    PERSONAL_AUTO: 'PersonalAutoLine',
    HOME_OWNER: 'HomeownersLine_HOE',
    PERSONAL_UMBRELLA: 'PUPLine_PUE',
    GENERAL_LIABILITY: 'GeneralLiabilityLine'
};

/**
 * @readonly
 * @enum {String}
 */
export const PRODUCT = {
    PERSONAL_AUTO: 'PersonalAuto',
    HOME_OWNER: 'Homeowners',
    PERSONAL_UMBRELLA: 'PersonalUmbrella',
    COMMERCIAL_PACKAGE: 'CommercialPackage'
};

export const OFFERING = {
    ContractorsChoice: 'CP_CCChoiceEnhancement_WMIC',
    NoEnhancement: 'CP_CIPNoEnhancement_WMIC'
};

/**
 * @readonly
 * @enum {String}
 */
export const EMAIL_VALIDATION_STATUS = {
    DRAFT: 'Draft',
    ERROR: 'Error',
    VALID: 'valid',
    INVALID: 'invalid',
    INVALID_TLS: 'invalid_tls'
};

/**
 * @readonly
 * @enum {String}
 */
export const DISTRIBUTION_METHODS = {
    MAIL: 'mail'
};

export const MODAL_CONSTANTS = {
    STATUS: {
        INFO: 'info',
        ERROR: 'error',
        WARNING: 'warning',
        SUCCESS: 'success'
    },
    ICON: {
        INFO: 'gw-help',
        ERROR: 'mi-report',
        WARNING: 'mi-warning'
    }
};

export const STATUS_CONTAINER_ICONS = {
    INFO: 'mi-info',
    WARNING: 'mi-warning',
    CHECK: 'mi-check',
    GW_INFO: 'gw-info',
    CLOSE: 'mi-close'
}

export const BUTTON_CONSTANTS = {
    BUTTON_DEFAULT_SIZE: 'small',
    BUTTON_BASE_CLASSNAME: 'ww-btn',
    BUTTON_ALLOWED_SIZES: ['small', 'medium'],
    DROPDOWN_BUTTON_DEFAULT_ICON: 'mi-expand-more',
    TYPES: {
        PRIMARY: 'primary',
        SECONDARY: 'secondary',
        INFO: 'info',
        SUCCESS: 'success',
        ERROR: 'error',
        WARNING: 'warning'
    },
    MODIFIERS: {
        FILLED: 'filled',
        OUTLINED: 'outlined',
        TEXT: 'text'
    },
    // Creates allowed "type" list enum PropType from above TYPES and MODIFIERS;
    // e.g. ['primary', 'primary-filled', 'primary-outlined', ...,
    //       'secondary', 'secondary-filled', ...];
    get BUTTON_ALLOWED_TYPES() {
        return [...Object.values(this.TYPES), ...Object.values(this.TYPES).map((type) =>
            Object.values(this.MODIFIERS).map((modifier) => `${type}-${(modifier)}`))]
            .flat()
    } ,
    get BUTTON_DEFAULT_TYPE() {
        return `${this.TYPES.SECONDARY}-${this.MODIFIERS.FILLED}`
    }
};

export const MASTER_INPUT = {
    DROPDOWN_TYPES: ['dropdownselect', 'dropdown'],
    CHECKBOX_TYPES: ['checkbox', 'check', 'checkbox_group',],
    TOGGLE_TYPES: ['toggle'],
    CONTROL_TYPES: ['typelist', 'text', 'boolean', 'number'],
    RADIO_TYPES: ['radio'],
    TEXT_AREA: ['text_area'],
    TYPE_AHEAD: ['typeahead'],
    YEAR: ['year'],
};

export const FILE_UPLOAD = {
    ACCEPTED_IMAGE_TYPES: '.jpg, .gif, .png',
    ACCEPTED_FILE_TYPES: '.xls,.xlsx, .pdf, .txt, .doc, .docx'
}

export const QUOTE_STATUS = {
    QUOTED: "Quoted",
    WITHDRAWN: "Withdrawn",
    DECLINED: 'Declined',
    DRAFT: "Draft",
    BOUND: "Bound",
    NOT_TAKEN: "NotTaken"
}

export const UW_BLOCKING_POINT = {
    REJECTED: "Rejected",
    NON_BLOCKING: "NonBlocking",
    BLOCK_ISSUANCE: "BlocksIssuance",
    BLOCK_BIND: "BlocksBind",
    BLOCK_QUOTE: "BlocksQuote"
}

export const TRANSACTION_STATUS = {
    QUOTED: 'Quoted',
    DRAFT: 'Draft',
    RENEWING: 'Renewing',
    NOT_TAKING: 'NotTaking',
    NON_RENEWING: 'NonRenewing',
    BOUND: 'Bound',
    BOUND_FR: 'Engagé',
    NOT_TAKEN: 'NotTaken',
    NON_RENEWED: 'NonRenewed',
    WITHDRAWN: 'Withdrawn',
    CANCELING: 'Canceling',
    DRAFT_FR: 'Brouillon',
    QUOTED_FR_1: 'Devis établi',
    QUOTED_FR_2: 'Soumission tarifiée',
    CANCELING_FR: 'Résiliation en cours...',
}

export const PENDING_TRANSACTION_STATUS = [
    TRANSACTION_STATUS.DRAFT,
    TRANSACTION_STATUS.QUOTED,
    TRANSACTION_STATUS.RENEWING,
    TRANSACTION_STATUS.NOT_TAKING,
    TRANSACTION_STATUS.NON_RENEWING,
    TRANSACTION_STATUS.CANCELING
];
export const COMPLETED_TRANSACTION_STATUS = [
    TRANSACTION_STATUS.BOUND,
    TRANSACTION_STATUS.NOT_TAKEN,
    TRANSACTION_STATUS.NON_RENEWED,
    TRANSACTION_STATUS.WITHDRAWN,
];

export const PERIOD_DISPLAY_STATUS = {
    SCHEDULED: 'Scheduled',
    SCHEDULED_FR: 'Prévu',
}

export const RENEWAL_DISPLAY_STATUSES = {
    AVAILABLE_FOR_CHANGES: 'Available for Changes',
    AVAILABLE_FOR_CHANGES_FR: 'Disponible pour modifications',
    PENDING_UNDERWRITER_REVIEW: 'Pending Underwriter Review',
    PENDING_UNDERWRITER_REVIEW_FR: 'En attente d’examen par un souscripteur',
}

export const TRANSACTION_DESTINATIONS = {
    QUOTE: 'quote',
    CHANGE: 'change',
    CANCELLATION: 'cancellation',
    RENEWAL: 'renewal'
}

export const SUBMISSION_PATHS = {
    BASE_STATE_PATH: 'baseData.baseState',
    BASE_STATE_VALUE: 'baseData.baseState.value.code',
    RATE_AS_OF_DATE_PATH: 'baseData.rateAsOfDate',
    RATE_AS_OF_DATE_VALUE: 'baseData.rateAsOfDate.value'
}

export const WIZARD_STEP_PATHS = {
    HO: {
        YOUR_HOME: '/your-home',
        CONSTRUCTION: '/construction',
        RISKS: '/risks',
        SCHEDULED_ITEMS: '/scheduled-items',
    },
    PA: {
        DRIVERS: '/drivers',
        VEHICLES: '/vehicles',
    },
    PUP: {
        UNDERLYING_POLICIES: '/underlying-policies',
        HOUSEHOLD_MEMBERS: '/household-members',
    },
    COMMON: {
        CHANGE_SUMMARY: '/change-summary',
        POLICY_DETAILS: '/policy-details',
        INSURANCE_HISTORY: '/insurance-history',
        PAYMENT_DETAILS: '/payments-details',
        QUOTE: '/quote',
    },
};

export const REDIRECT_PATHS_FOR_JOB_TYPES = {
    QUOTE_PAGE_URL(jobID) {
        return `/quotes/${jobID}/summary`;
    },
    POLICYCHANGE_URL(jobID) {
        return `/change/${jobID}/summary`;
    },
    CANCELLATION_URL(jobID) {
        return `/cancellation/${jobID}/summary`;
    },
    RENEWAL_URL(jobID) {
        return `/renewal/${jobID}/summary`;
    }
};

export const BOT_STATUS = {
    AUTO_APPROVED_UW_ISSUE: 'YELLOW',
    MANUAL_APPROVED_UW_ISSUE: 'ORANGE'
};

export const ERROR_STATUS_CODES = {
    DUPLICATE_BANK_RECORD: 780,
    WMIC_NEGATIVE_INVOICE_STREAM: 781
};
