/* eslint-disable no-secrets/no-secrets */
import _ from 'lodash';
import {
    CONSTANTS,
    PAConstants,
    LINE_OF_BUSINESS,
    WMICVehicleUtil,
    POLICY_DETAILS,
    WMICDateTimeService,
    JURISDICTIONS
} from 'wmic-pe-portals-utils-js';

const CREDIT_CONSENT_VEHICLE_TYPES = ['auto', 'motorhome'];
const VEHICLE_BUSINESS_SEGMENT_PERSONAL_CODE = 'Personal';

export default class WMICCreditConsentUtil {
    static HO_CREDIT_CONSENT_ENHANCEMENT_DATE = new Date(2021, 5, 15);

    static hasOnlyIRCAVehicles(vehicles) {
        return vehicles
            && vehicles.length > 0
            && !vehicles.some((vehicle) =>
                vehicle.businessSegment_WMIC !== PAConstants.vehicleBusinessSegmentCommercial
            );
    }

    static isPniCreditConsentable (pni) {
        const subtype = _.get(pni, 'subtype.value');
        const estateOf = _.get(pni, 'estateOf_WMIC.value');
        return subtype === CONSTANTS.Person && !estateOf;
    };

    static isPersonCreditConsentable (person) {
        //Can be used for both PNI's and ANI's
        const estateOf = _.get(person, 'estateOf_WMIC.value');
        return person?.subtype?.value?.toLowerCase() === CONSTANTS.Person.toLowerCase() && !estateOf;
    };

    static showPaymentPlan(jurisdiction) {
        return ['AB', 'YT'].includes(jurisdiction);
    };

    static isHOCreditConsentTabVisibleOnInsuranceHistory(policyEffectiveDate, anis) {
        return (new Date(policyEffectiveDate).getTime() >= WMICCreditConsentUtil.HO_CREDIT_CONSENT_ENHANCEMENT_DATE.getTime())
            || this.isAnyAniCreditConsentable(anis);
    };

    static isCreditInfoWithdrawalConsentApplicable(consentOption, rateAsOfDate, jurisdiction, lob, vehicles) {
        const isPersonalAuto = this.isPersonalAuto(lob, vehicles);
        return this.isCreditFieldsApplicable(rateAsOfDate, jurisdiction, isPersonalAuto)
            && [CONSTANTS.CREDIT_CONSENT_CODES.NO, CONSTANTS.CREDIT_CONSENT_CODES.WITHDRAWN].includes(consentOption);
    };

    static hasPersonalVehicleOrMotorHome(vehicles) {
        let found = false;

        if(vehicles) {
            const isCreditCheckedVehicle = (vehicle) =>
                CREDIT_CONSENT_VEHICLE_TYPES.includes(vehicle.vehicleType)
                    && vehicle.businessSegment_WMIC === VEHICLE_BUSINESS_SEGMENT_PERSONAL_CODE;

            for(let i = 0, j = vehicles.length - 1; !found && i <= (j - i); i+=1) {
                found = isCreditCheckedVehicle(vehicles[i]) || isCreditCheckedVehicle(vehicles[j-i])
            }
        }

        return found;
    };

    static isPersonalAuto(lob, vehicles) {
        return lob === LINE_OF_BUSINESS.PERSONAL_AUTO
            && vehicles
            && !vehicles.some((veh) => WMICVehicleUtil.isCommerciallyRatedVehicleType(veh.businessSegment_WMIC, veh.vehicleType));
    };

    static isCreditFieldsApplicable(rateAsOfDate, jurisdiction, isPersonalAuto) {
        const creditFieldsEffectiveDate = new Date(2021, 3, 1);
        return !(isPersonalAuto
            && POLICY_DETAILS.BLUEPASS_SUPPORTED_JURISDICTIONS_EXCEPT_QC.includes(jurisdiction)
            && WMICDateTimeService.compareIgnoreTime(WMICDateTimeService.localDate2Date(rateAsOfDate), creditFieldsEffectiveDate) >= 0);
    };

    static isAniCreditConsentable (ani) {
        return ani?.subtype?.value?.code === CONSTANTS.Person.toLowerCase()
            && !ani.estateOf_WMIC.value;
    };

    static getCreditConsentableAnis(jobVM, viewModelService) {
        const anis = _.get(jobVM, 'baseData.additionalNamedInsureds_WMIC.children', []);
        return anis.filter((ani) => {
            const _ani = this.populateContextIfEmpty(ani, jobVM, viewModelService, {"LobContext": jobVM.lob.value});
            return this.isAniCreditConsentable(_ani);
        })
    }

    static updateAdditionalNamedInsured(jobVM, additionalNamedInsureds, updatedAni, index) {
        const allConsentableAnis = [...additionalNamedInsureds];
        allConsentableAnis[index] = updatedAni;

        const allAnis = _.get(jobVM, 'baseData.additionalNamedInsureds_WMIC.children', []).map((ani) => {
            if (!WMICCreditConsentUtil.isAniCreditConsentable(ani)) {
                return ani.value;
            }
            const newAniIndex = allConsentableAnis.findIndex((consentableAni) => consentableAni?.contactPublicID?.value === ani?.contactPublicID?.value);
            if (newAniIndex > -1) {
                return allConsentableAnis[newAniIndex].value;
            }
            return ani.value;
        });
        _.set(jobVM, 'baseData.additionalNamedInsureds_WMIC.value', allAnis);
        return allConsentableAnis;
    }

    static syncBaseDataToDriverLobData (selectedDriver, baseData) {
        if (selectedDriver && baseData?.baseState === JURISDICTIONS.QUEBEC) {
            if (selectedDriver.isPrimaryInsured_WMIC) {
                baseData.creditConsentReceived = selectedDriver.creditConsentReceived;
                baseData.creditConsentDate = selectedDriver.creditConsentDate;

                this.syncBaseDataSubQuestionsToDriverData(baseData, selectedDriver);
            }
            else {
                const foundANI = baseData.additionalNamedInsureds_WMIC.find((ani) =>
                    ani.contactPublicID === selectedDriver.person.publicID);

                if (foundANI) {
                    foundANI.creditConsentReceived = selectedDriver.creditConsentReceived;
                    foundANI.creditConsentDate = selectedDriver.creditConsentDate;

                    this.syncBaseDataSubQuestionsToDriverData(foundANI, selectedDriver);
                }
            }
        }
    };

    static syncBaseDataSubQuestionsToDriverData(baseData, selectedDriver) {
        if (baseData.creditConsentReceived === CONSTANTS.CREDIT_CONSENT_CODES.YES) {
            baseData.personalInfoConsentForm = selectedDriver.personalInfoConsentForm;
        } else {
            baseData.creditInfoWithdrawalConsent = selectedDriver.creditInfoWithdrawalConsent;
        }
    };

    static isAnyAniCreditConsentable (anis) {
        let found = false;

        if(anis) {
            const isCreditCheckedPerson = (ani) =>
                ani.subtype.toLowerCase() === CONSTANTS.Person.toLowerCase() && !ani.estateOf_WMIC;

            for(let i = 0, j = anis.length - 1; !found && i <= (j - i); i+=1) {
                found = isCreditCheckedPerson(anis[i]) || isCreditCheckedPerson(anis[j-i])
            }
        }

        return found;
    }

    static isContextEmpty (context) {
        if (_.isEmpty(context) || _.isNil(context)) {
            return true;
        }
        const isContextCorrupted = Object.entries(context).every(([key, value]) => (
            key === '[object Object]' && _.isNil(value)
        ))
        return !!isContextCorrupted;
    };

    static populateContextIfEmpty(property, jobVM, viewModelService, contextArgs) {
        if (this.isContextEmpty(property?.aspects?.context())) {
            const args = contextArgs ?
                { ...jobVM.aspects.context(), ...contextArgs } :
                jobVM.aspects.context();
            return viewModelService.changeContext(
                property,
                args
            );
        }
        return property;
    }

    static updateAniProps(additionalNamedInsureds, setAdditionalNamedInsured, onValidateConsentMap, showErrors, readOnly) {
        const aniOverrides = additionalNamedInsureds.map((ani, aniIndex) => {
            const newOverrides = {
                [`creditConsentANIComponent${aniIndex}`]: {
                    setData: setAdditionalNamedInsured,
                    onValidateConsentMap,
                    showErrors,
                    readOnly,
                }
            }
            return newOverrides;
        })
        return Object.assign({}, ...aniOverrides);
    };

    static isConsentValid(consentValidMap) {
        return Object.values(consentValidMap).every((cValid) => cValid === true);
    }
}