import { defineMessages } from 'react-intl';

export default defineMessages({
    accountTitle: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.accountTitle',
        defaultMessage: 'Account:'
    },
    personalAuto: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.personalAuto',
        defaultMessage: 'Automobile Policy Change ({jobNumber})'
    },
    personalUmbrella: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.personalUmbrella',
        defaultMessage: 'Personal Umbrella Policy Change ({jobNumber})'
    },
    homeowners: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.homeowners',
        defaultMessage: 'Personal Property Policy Change ({jobNumber})'
    },
    continueSubmissionHeader: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.continuePolicyChangeHeader',
        defaultMessage: 'Continue Policy Change'
    },
    policyChangePremiumCostsCardTitle: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.policyChangePremiumCostsCardTitle',
        defaultMessage: 'Premium Costs'
    },
    policyChangeUnderwritingIssuesCardCardTitle: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.policyChangeUnderwritingIssuesCardCardTitle',
        defaultMessage: 'Underwriting Issues'
    },
    policyChangeDraftHeading: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.policyChangeDraftHeading',
        defaultMessage: 'Draft'
    },
    policyChangeDraftBody: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.policyChangeDraftBody',
        defaultMessage: 'The policy change was successfully started.'
    },
    policyChangeQuotedUWHeading: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.policyChangeQuotedUWHeading',
        defaultMessage: 'Quoted: Underwriting Issues Have Been Raised'
    },
    policyChangeQuotedUWFVHeading: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.Quoted: Validation and Underwriting Issues Have Been Raised',
        defaultMessage: 'Quoted: Validation and Underwriting Issues Have Been Raised'
    },
    policyChangeQuotedFVHeading: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.Quoted: Validation Issues Have Been Raised',
        defaultMessage: 'Quoted: Validation Issues Have Been Raised'
    },
    policyChangeQuotedUWBody: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.policyChangeQuotedUWBody',
        defaultMessage: 'You cannot complete this transaction until these issues have been resolved.'
    },
    policyChangeexpiredHeading: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.policyChangeExpiredHeading',
        defaultMessage: 'The transaction requested is for the previous term.  Please withdraw the transaction and create a new one for the current term.'
    },
    policyChangeWithdrawnHeading: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.policyChangeWithdrawnHeading',
        defaultMessage: 'This policy change has been withdrawn.'
    },
    policyChangeQuotedHeading: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.policyChangeApprovedHeading',
        defaultMessage: 'Approved: Ready to Bind'
    },
    policyChangeQuotedBody: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.policyChangeApprovedBody',
        defaultMessage: 'There are no outstanding issues.'
    },
    autoApprovedUWIssuesTitle: {
        id:'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.autoApprovedTitle',
        defaultMessage: 'The following will be auto-approved and may be subject to a compliance review'
    },
    continuePolicyChangeButton: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.continuePolicyChangeButton',
        defaultMessage: 'Continue'
    },
    editPolicyChangeButton: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.editPolicyChangeButton',
        defaultMessage: 'Edit'
    },
    withdrawPolicyChangeButton: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.withdrawPolicyChangeButton',
        defaultMessage: 'Withdraw'
    },
    withdrawPolicyChangeHeading: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.withdrawPolicyChangeHeading',
        defaultMessage: 'Withdraw'
    },
    withdrawPolicyChangeBody: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.withdrawPolicyChangeBody',
        defaultMessage: 'Are you sure you want to withdraw this policy change?'
    },
    uwMustReviewTitle: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.uwMustReviewTitle',
        defaultMessage: 'An underwriter must review the following ({count}):'
    },
    noOutstandingUWIssuesTitle: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.noOutstandingUWIssuesTitle',
        defaultMessage: 'There are no outstanding underwriting issues'
    },
    underwriterApprovalPending: {
        id: 'wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.Quoted: Underwriter Approval Pending',
        defaultMessage: 'Quoted: Underwriter Approval Pending'
    },
    policyCancelledStatusMessageHeading: {
        id: "wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.Cancelled Policy",
        defaultMessage: "Cancelled Policy"
    },
    policyCancelledStatusMessageBody: {
        id: "wmic-pe-capability-gateway-react.WMICEndorsementDetailsPage.Policy Change cannot be completed on cancelled policies",
        defaultMessage: "Policy Changes cannot be completed on cancelled policies. Please submit to underwriting if you wish to proceed."
    }
});
