import React, { useContext, useCallback } from 'react';
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import _ from 'lodash';
import cx from 'classnames';
import { CONSTANTS, WMICBMSIUtil } from 'wmic-pe-portals-utils-js'
import PropTypes from 'prop-types';

import { Grid } from '@jutro/layout';
import { WMICCard } from 'wmic-pe-components-platform-react';

import styles from './WMICDataList.module.scss';
import WMICDataListCardSummary from '../WMICCardSummary/WMICDataListCardSummary';

const dataListPropTypes = {
    /**
     * Array of VMs to be shown
     */
    VMList: PropTypes.array.isRequired,
    /**
     * Object array containing the headerText & VM paths for the cards
     * Example VMData: [
                {
                    headerText: translator(messages.driverName),
                    path: 'displayName'
                },
                {
                    headerText: translator(messages.licenseNumber),
                    path:'licenseNumber'
                },
                {
                    headerText: translator(messages.licenseProvince),
                    path:'licenseState'
                }
            ],
     */
    VMData: PropTypes.arrayOf(
        PropTypes.shape({
            headerText: PropTypes.string,
            path: PropTypes.string,
            getData: PropTypes.func,
            isTypekey: PropTypes.bool,
            visibilityCondition: PropTypes.func
        })
    ).isRequired,
    /**
     * Callback for when the edit button on the list is called. If not received the button will not appear
     */
    onEditAction: PropTypes.func,
    /**
     * Callback for when the remove button on the list is called. If not received the button will not appear
     */
    onRemoveAction: PropTypes.func,
    /**
     * Boolean indicating if the card is clickable
     */
    clickable: PropTypes.bool,
    /**
     * Index indicating the selected card
     */
    selectedCardIndex: PropTypes.number.isRequired,
    /**
     * Callback for when a new card is selected
     */
    updateSelectedCardIndex: PropTypes.func.isRequired,
    /**
     * Boolean indicating if the VM of a card is being edited
     */
    isEditing: PropTypes.bool,
    /**
     * Boolean indicating if the cards should be flat e.g. no shadow
     */
    flatCards: PropTypes.bool,
    /**
     * Boolean indicating if the cards should be readOnly e.g with no edit/delete buttons
     */
    readOnly: PropTypes.bool,
};

function WMICDataList(props) {
    const {
        VMList,
        VMData,
        onEditAction,
        onRemoveAction,
        clickable,
        selectedCardIndex,
        updateSelectedCardIndex,
        isEditing,
        flatCards,
        readOnly,
        onClickAction,
        className,
        isEditDisabled,
        isDeleteDisabled,
        dataListTitle,
        dataListSubtitle,
        selectAction,
        selectButtonMessage,
        canDelete,
        canEdit,
        customElement,
        gridProps,
        id
    } = props;
    const { wizardData: jobVM, updateWizardData: updateJobVM, currentStep } = useContext(WizardContext) || {};
    const isBMSI = _.get(jobVM, "isFromBMS_WMIC.value", false);

    const getBmsiFields = useCallback((node) => {
        const bmsiFields = [];
        const defaultedProperties = _.get(jobVM, 'defaultedProperties_WMIC.value') || [];
        const stepRegexForBmsiObjects = currentStep?.bmsiRegexPathToDisplayObjects;
        const getDefPropIfWholeNodeDefaulted = (defProp, nodeToCheck) => {
            // checks if the whole entity has been defaulted and not jus a specific field
            const results = defProp.filter((d) => !d.entityPropertyName && d.className === nodeToCheck?._dtoName && d.classPublicID === nodeToCheck?.publicID?.value);
            return results?.length === 1 ? results : undefined;
        };

        const parentNodeDefaulted = getDefPropIfWholeNodeDefaulted(defaultedProperties, node);
        if (parentNodeDefaulted) {
            return parentNodeDefaulted;
        }

        const condition = (n) => {

            const checkWithStepRegex = (nodeVM) => {
                const pathToItem = nodeVM?.baseVMNode?.pathToItem?.asString || '';
                return stepRegexForBmsiObjects.some((reg) => (new RegExp(reg, 'i')).test(pathToItem));
            };

            const defaultedNodeProp = getDefPropIfWholeNodeDefaulted(defaultedProperties, n);
            if (defaultedNodeProp) {
                if (stepRegexForBmsiObjects) {
                    if (checkWithStepRegex(n)) {
                        bmsiFields.push(defaultedNodeProp);
                        if (defaultedNodeProp.status !== CONSTANTS.REVIEWED) { // found the fist non-reviewed property, no need to check if there are more
                            return true;
                        }
                    }
                } else {
                    bmsiFields.push(defaultedNodeProp);
                    if (defaultedNodeProp.status !== CONSTANTS.REVIEWED) {
                        return true;
                    }
                }
            }

            const dtoProps = n?._metadataInfo.valueType.typeInfo.properties || [];
            dtoProps.forEach((dtoProp) => {
                const defaultedProp = defaultedProperties.find((p) =>
                    p.className === n?._dtoName
                    && p.classPublicID === n?.publicID?.value
                    && dtoProp.name === p.propertyName
                );

                if (defaultedProp) {
                    if (stepRegexForBmsiObjects) {
                        if (n._dtoName === CONSTANTS.POLICY_CLAIM_DTO_NAME || checkWithStepRegex(n[dtoProp.jsProperty])) {
                            bmsiFields.push(defaultedProp);
                            if (defaultedProp.status !== CONSTANTS.REVIEWED) {
                                return true;
                            }
                        }
                    } else {
                        bmsiFields.push(defaultedProp);
                        if (defaultedProp.status !== CONSTANTS.REVIEWED) {
                            return true;
                        }
                    }
                }
            });
        };

        WMICBMSIUtil.checkSubtree(node, condition, true, true);

        return bmsiFields;
    }, [jobVM, currentStep?.bmsiRegexPathToDisplayObjects]);

    const removeRow = useCallback((nodeVM, rowIndex) => {
        let propertiesToBeRemoved = [];
        const defaultedProperties = _.get(jobVM, 'defaultedProperties_WMIC.value') || [];

        if(defaultedProperties.length > 0){
            propertiesToBeRemoved = getBmsiFields(nodeVM);
        }

        Promise.resolve(onRemoveAction(nodeVM, rowIndex)).then(() => {
            if (propertiesToBeRemoved.length > 0) {
                const defaultedPropertiesWithRemovedRows = defaultedProperties.filter((property) => {
                    return !propertiesToBeRemoved.some((row) => {
                        return row.entityPropertyName === property.entityPropertyName && row.classPublicID === property.classPublicID
                    })
                })

                _.set(jobVM, 'defaultedProperties_WMIC.value', defaultedPropertiesWithRemovedRows);
                updateJobVM(jobVM)
            }
        });
    }, [jobVM, onRemoveAction, getBmsiFields, updateJobVM]);

    const isRowReadOnly = (row) => _.get(row, 'isReadOnly.value', false)

    return VMList !== undefined && VMList.length > 0 ? (
        <Grid vgap="medium" key='DataListItemsWrapper' className={cx("gw-mb-4", className)} id={id}>
            {VMList.map((row, rowIndex) => {
                return (
                    <WMICCard
                        id={`${id}_${rowIndex}_ItemsWrapper`}
                        key={`${id}_${rowIndex}_ItemsWrapper`}
                        flat={flatCards}
                        className={cx({
                            [styles.listView]: !clickable,
                            [styles.listViewHover]: clickable,
                            [styles.selectedCard]: rowIndex === selectedCardIndex
                        })}
                    >
                        <WMICDataListCardSummary
                            id={`${id}_${rowIndex}_Row`}
                            clickable={clickable}
                            isEditing={isEditing}
                            updateSelectedCardIndex={updateSelectedCardIndex}
                            row={row}
                            rowIndex={rowIndex}
                            onClickAction={onClickAction}
                            VMList={VMList}
                            VMData={VMData}
                            dataListTitle={dataListTitle}
                            dataListSubtitle={dataListSubtitle}
                            readOnly={readOnly || isRowReadOnly(row)}
                            onEditAction={onEditAction}
                            onRemoveAction={removeRow}
                            isEditDisabled={isEditDisabled}
                            canDelete={canDelete}
                            canEdit={canEdit}
                            isDeleteDisabled={isDeleteDisabled}
                            selectAction={selectAction}
                            selectButtonMessage={selectButtonMessage}
                            confirmable={isBMSI && getBmsiFields(row).length > 0}
                            confirmed={isBMSI && getBmsiFields(row).every((f) => f.status === CONSTANTS.REVIEWED)}
                            customElement={customElement}
                            gridProps={gridProps}
                        />
                    </WMICCard>
                );
            })}
        </Grid>
    ) : null;
};

WMICDataList.propTypes = dataListPropTypes;

WMICDataList.defaultProps = {
    clickable: true,
    isEditing: false,
    flatCards: false,
    readOnly: false
};

export default WMICDataList;
