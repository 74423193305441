/* eslint-disable no-secrets/no-secrets */
import React, {
    useContext,
    useEffect,
    useState,
    useMemo
} from 'react';
import _ from 'lodash';
import appConfig from 'app-config';
import { Loader } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { withRouter } from 'react-router-dom';
import { JobService } from 'gw-capability-gateway';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import {
    QUOTE_STATUS,
    UW_BLOCKING_POINT,
    CONSTANTS,
    PRODUCT,
    BOT_STATUS,
    WMICUserAccessUtil,
    WMICLogger,
    parseErrorMessage
} from 'wmic-pe-portals-utils-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { WMICPremiumCosts, WMICGoogleTagManagerUtil } from 'wmic-pe-capability-gateway-common-react';
import { STATUS_CONTAINER_ICONS } from 'wmic-pe-portals-utils-js/StringConstants';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';
import { WMICUnderwritingIssuesList } from 'wmic-pe-components-platform-react';

import metadata from './WMICEndorsementDetailsPage.metadata.json5';
import messages from './WMICEndorsementDetailsPage.messages';
import styles from './WMICEndorsementDetailsPage.module.scss';

const WMICEndorsementDetailsPage = (props) => {
    const { authHeader, authUserData } = useAuthentication();
    const translator = useContext(TranslatorContext);
    const { PolicyChangeService } = useDependencies('PolicyChangeService');

    const [isLoading, setIsLoading] = useState(true);
    const [policyChange, setPolicyChange] = useState();
    const [policyChangeVM, setPolicyChangeVM] = useState();
    const [lobWithJobNumber, setLobWithJobNumber] = useState('');
    const [status, setStatus] = useState('');
    const [quotedWithUW, setQuotedWithUW] = useState(false);
    const [approvedUWIssues, setApprovedUWIssues] = useState();
    const [autoApprovedUWIssues, setautoApprovedUWIssues] = useState();
    const [unapprovedUWIssues, setUnapprovedUWIssues] = useState();
    const { showConfirm } = useWizardModals();
    const [isTransactionPendingWithUW, setIsTransactionPendingWithUW] = useState(false);

    const { history } = props;

    const isReadOnlyUser = useMemo(() => !WMICUserAccessUtil.canCreateSubmission(authUserData.roles), [authUserData.roles]);
    const canWithdrawJob = useMemo(() => WMICUserAccessUtil.canWithdrawSubmission(authUserData.roles), [authUserData.roles]);

    const getJobByJobNumber = async () => {
        const {
            match: {
                params: { jobNumber }
            },
            viewModelService,
            history
        } = props;

        try {
            setIsLoading(true);
            const policyChangeResponse = await PolicyChangeService.findJobByJobNumber(jobNumber, authHeader);
            setLobWithJobNumber(
                translator(messages[_.camelCase(policyChangeResponse.productCode)], {
                    jobNumber: policyChangeResponse.jobNumber
                })
            );

            setIsTransactionPendingWithUW(policyChangeResponse?.isPendingWithUW ?? false);

            if (policyChangeResponse.policy.latestPeriod.canceled) {
                setStatus(CONSTANTS.CANCELED);
            } else if (policyChangeResponse.policy.latestPeriod.isExpired) {
                setStatus(CONSTANTS.EXPIRED);
            } else {
                setStatus(policyChangeResponse?.statusCode);
            }

            if (policyChangeResponse) {
                const lob = _.get(policyChangeResponse, 'productCode');
                const policyNumber = _.get(policyChangeResponse, 'policy.policyNumber');
                const policyData = _.get(policyChangeResponse, 'policy');
                WMICGoogleTagManagerUtil.addPolicyDataToGTagManager(jobNumber, policyNumber, lob, policyData);

                let vmType = '';
                let policyChangeResult = {};
                if(lob === PRODUCT.PERSONAL_AUTO){
                    vmType = 'wmic.edge.ca.capabilities.gateway.job.policychange.dto.PAPolicyChangeReviewDTO_WMIC';
                    policyChangeResult = await PolicyChangeService.getPAPolicyChangeSummary_WMIC(jobNumber, authHeader);
                }else if(lob === PRODUCT.HOME_OWNER){
                    vmType = 'wmic.edge.ca.capabilities.gateway.job.policychange.dto.HOPolicyChangeReviewDTO_WMIC';
                    policyChangeResult = await PolicyChangeService.getHOPolicyChangeSummary_WMIC(jobNumber, authHeader);
                }else if(lob === PRODUCT.PERSONAL_UMBRELLA){
                    vmType = 'wmic.edge.ca.capabilities.gateway.job.policychange.dto.PUPPolicyChangeReviewDTO_WMIC';
                    policyChangeResult = await PolicyChangeService.getPUPPolicyChangeSummary_WMIC(jobNumber, authHeader);
                }
                const policyChangeSummaryVM = viewModelService.create(policyChangeResult,'pc',vmType);
                setPolicyChangeVM(policyChangeSummaryVM);
            }

            setPolicyChange(policyChangeResponse);
        } catch (e) {
            WMICLogger.error('Error retrieving job', e);
            WMICErrorHandler.processAsModal(parseErrorMessage(e))
            history.push("/");
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getJobByJobNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const issueApproved = (issue) => issue.botStatus === BOT_STATUS.MANUAL_APPROVED_UW_ISSUE;
    
    const issueAutoApproved = (issue) => issue.botStatus === BOT_STATUS.AUTO_APPROVED_UW_ISSUE;
    
    const issueNotApproved = (issue) => ( issue.hasApprovalOrRejection === false || issue.currentBlockingPoint !== UW_BLOCKING_POINT.NON_BLOCKING ) && issue.botStatus === BOT_STATUS.MANUAL_APPROVED_UW_ISSUE;

    const quotedWithFieldValidations = policyChange?.statusCode === QUOTE_STATUS.QUOTED && _.get(policyChange, 'validationIssues.issues', []).length > 0;

    useEffect(() => {
        const UWIssues = _.get(policyChange, 'underwritingIssues', []);
        const approvedIssues = _.orderBy(UWIssues.filter((issue) => issueApproved(issue)), ['hasApprovalOrRejection']);
        const autoApprovedIssues = UWIssues.filter((issue) => issueAutoApproved(issue));
        const unapprovedIssues = _.orderBy(UWIssues.filter((issue) => issueNotApproved(issue)), ['hasApprovalOrRejection', 'publicID']);
        // eslint-disable-next-line no-unused-expressions
        unapprovedIssues.length > 0 && policyChange?.statusCode === QUOTE_STATUS.QUOTED && setQuotedWithUW(true);
        setApprovedUWIssues(approvedIssues);
        setUnapprovedUWIssues(unapprovedIssues);
        setautoApprovedUWIssues(autoApprovedIssues);
    }, [policyChange]);

    const getStatusIcon = () => {
        if (status === CONSTANTS.CANCELED) {
            return STATUS_CONTAINER_ICONS.GW_INFO;
        }
        if (quotedWithUW || quotedWithFieldValidations) {
            return STATUS_CONTAINER_ICONS.WARNING;
        }
        if (status === QUOTE_STATUS.QUOTED) {
            return STATUS_CONTAINER_ICONS.CHECK;
        }
        return STATUS_CONTAINER_ICONS.INFO;
    };

    const updateWithdrawPolicyChange = (withdrawnStatus, currentPolicyChange) => {
        setStatus(withdrawnStatus);
        _.set(currentPolicyChange, 'status', withdrawnStatus);
        _.set(currentPolicyChange, 'statusCode', withdrawnStatus);

        setPolicyChange((currentState) => ({...currentState, currentPolicyChange}));
    }

    const handleWithdrawPolicyChange = () => {
        showConfirm({
            title: translator(messages.withdrawPolicyChangeHeading),
            message: translator(messages.withdrawPolicyChangeBody),
            confirmButtonText: translator(messages.withdrawPolicyChangeButton),
            cancelButtonText: commonMessages.no,
        }).then((result) => {
            if (result === CONSTANTS.MODAL_RESULT.CANCEL || result === CONSTANTS.MODAL_RESULT.CLOSE) {
                return;
            }
            setIsLoading(true);
            JobService.withdrawJobByJobNumber(policyChange.jobNumber, authHeader).then(
                () => {
                    updateWithdrawPolicyChange(QUOTE_STATUS.WITHDRAWN, policyChange);
                }
            ).finally(() => {
                setIsLoading(false);
            });
        }, _.noop);
    };

    const handleContinuePolicyChange = () => {
        const { lobEndorsementURL } = appConfig;

        const productCode = _.get(policyChange, 'productCode');
        const postalCode = _.get(policyChange, 'policy.account.accountHolder.primaryAddress.postalCode');
        const policyNumber = _.get(policyChange, 'policy.policyNumber');
        const selectedTerm = _.get(policyChange, 'policy.latestPeriod.termNumber_WMIC');
        const effectiveDate = _.get(policyChange, 'effectiveDate');
        if (policyChange.jobNumber > 0) {
            if (!_.isNil(lobEndorsementURL[productCode])) {
                const nextLocation = {
                    policyNumber,
                    selectedTerm,
                    policyChangeEntry: {
                        postalCode,
                        jobID: policyChange.jobNumber,
                        effectiveDate
                    },
                    productCode
                };
                history.push(lobEndorsementURL[productCode], nextLocation);
            }
        }
    }

    const statusMessageHeading = useMemo(() => {
        if (status === CONSTANTS.CANCELED) {
            return translator(messages.policyCancelledStatusMessageHeading);
        }
        if (['Quoted: Underwriter Approval Pending'].includes(_.get(policyChangeVM, 'displayStatus_WMIC.value', '')) || isTransactionPendingWithUW) {
            return translator(messages.underwriterApprovalPending);
        }
        if (quotedWithFieldValidations && quotedWithUW) {
            return translator(messages[`policyChange${status}UWFVHeading`])
        }
        if (quotedWithFieldValidations) {
            return translator(messages[`policyChange${status}FVHeading`])
        }
        if (quotedWithUW) {
            return translator(messages[`policyChange${status}UWHeading`])
        }
        return translator(messages[`policyChange${status}Heading`]);
    },[policyChangeVM, quotedWithFieldValidations, quotedWithUW, status, translator]);

    const getStatusMessageBody = () => {
        if (status === CONSTANTS.CANCELED) {
            return translator(messages.policyCancelledStatusMessageBody);
        }
        if(isTransactionPendingWithUW) {
            return translator(messages.policyChangeQuotedUWBody)
        }
        if (quotedWithUW || quotedWithFieldValidations) {
            return translator(messages[`policyChange${status}UWBody`]);
        }
        return translator(messages[`policyChange${status}Body`]);
    }

    const getCurrentPremiumData = () => ({
            totalBeforeTaxes: _.get(policyChange, 'policy.latestPeriod.totalPremium'),
            taxes: _.get(policyChange, 'policy.latestPeriod.taxes'),
            fees: _.get(policyChange, 'policy.latestPeriod.fees'),
            total: _.get(policyChange, 'policy.latestPeriod.totalCost')
        })

    const isActionButtonsContainerVisible = () => {
        return ![QUOTE_STATUS.WITHDRAWN, QUOTE_STATUS.DECLINED, QUOTE_STATUS.BOUND, QUOTE_STATUS.NOT_TAKEN].includes(policyChange?.statusCode)
            && status !== CONSTANTS.CANCELED
    }

    const continuePolicyChangeLabel = quotedWithUW || quotedWithFieldValidations ? messages.editPolicyChangeButton : messages.continuePolicyChangeButton;

    const overrideProps = {
        statusMessageHeading: {
            content: statusMessageHeading
        },
        statusMessageBody: {
            visible: policyChange?.statusCode !== QUOTE_STATUS.WITHDRAWN,
            content: getStatusMessageBody()
        },
        statusIcon: {
            icon: getStatusIcon(),
        },
        transactionDetailsHeader: {
            jobVM: policyChange,
            lobWithJobNumber: lobWithJobNumber,
            history: history
        },
        premiumCostsCard: {
            visible: policyChange?.statusCode === QUOTE_STATUS.QUOTED,
            premium: getCurrentPremiumData(),
            jobVM: policyChange,
            policyChange: policyChangeVM
        },
        actionButtonsContainer: {
            visible: isActionButtonsContainerVisible()
        },
        UWIssuesRaisedContainer: {
            visible: unapprovedUWIssues?.length > 0
        },
        noOutstandingUWIssuesContainer: {
            visible: unapprovedUWIssues?.length === 0
        },
        autoApprovedUWIssuesContainer: {
            visible: autoApprovedUWIssues?.length > 0
        },
        UWIssuesRaisedSubTitle: {
            title: translator(messages.uwMustReviewTitle, {count: unapprovedUWIssues?.length})
        },
        raisedUWIssuesItems: {
            underwritingIssues : approvedUWIssues
        },
        approvedUWIssuesItems: {
            underwritingIssues: approvedUWIssues
        },
        autoApprovedUWIssuesItems:{
            underwritingIssues: autoApprovedUWIssues
        },
        underwritingIssuesCard: {
            visible: policyChange?.statusCode === QUOTE_STATUS.QUOTED
        },
        withdrawPolicyChange: {
            visible: !isReadOnlyUser && canWithdrawJob
        },
        continuePolicyChange: {
            content: continuePolicyChangeLabel,
            visible: status !== CONSTANTS.EXPIRED
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onWithdrawPolicyChange: handleWithdrawPolicyChange,
            onContinuePolicyChange: handleContinuePolicyChange,
        },
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICPremiumCosts,
            WMICUnderwritingIssuesList
        }
    };

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    if (_.isEmpty(policyChange)) {
        return null;
    }

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

export default withRouter(withViewModelService(WMICEndorsementDetailsPage));
